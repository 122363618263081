import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import * as d3 from "d3";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Link } from "react-router-dom/dist";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  textDecoration: "none",

  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    textDecoration: "underline",
  },
}));

const PerformanceList = ({ data }) => {
  const nestedData = d3.rollups(
    data,
    (v) =>
      d3.sum(v, (d) =>
        d.directives.reduce(
          (sum, directive) => sum + (directive.budget || 0),
          0
        )
      ),
    (d) => d.plan
  );

  // Sort the nestedData in descending order based on the summed budgets
  const sortedNestedData = nestedData.sort((a, b) => b[1] - a[1]);

  return (
    <JumboCardQuick
      title={"Cost distribution by plan"}
      action={
        <Tooltip
          title={"No data available"}
          arrow
          placement={"top"}
          sx={{
            "& .MuiTooltip-arrow": {
              marginTop: "-0.65em",
            },
          }}
        >
          <IconButton
          //   onClick={() => setShowChart(!showChart)}
          >
            {/* <InsertChartOutlinedIcon /> */}
          </IconButton>
        </Tooltip>
      }
      wrapperSx={{ p: 0 }}
    >
      <JumboScrollbar
        autoHeight={400}
        autoHideTimeout={4000}
        autoHeightMin={450}
        autoHide={true}
        hideTracksWhenNotNeeded
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ pl: 3 }}>Plan</StyledTableCell>
              {/* <StyledTableCell align={"right"}>Performance</StyledTableCell>
              <StyledTableCell /> */}
              <StyledTableCell sx={{ pr: 3 }} align={"right"}>
                Cost
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedNestedData.map((item, index) => (
              <StyledTableRow
                key={index}
                component={Link}
                to={`/directives?plan=${item[0]}&cost=${item[1]}`}
              >
                <TableCell sx={{ pl: (theme) => theme.spacing(3) }}>
                  {item[0]}
                </TableCell>
                {/* <TableCell align="right">{item.category}</TableCell>
                <TableCell align="left">
                  <Span sx={{ whiteSpace: "nowrap" }}>
                    {item.main_directive_percentage * 100}%
                  </Span>
                </TableCell> */}
                <TableCell
                  align="right"
                  sx={{ pr: (theme) => theme.spacing(3) }}
                >
                  KES {item[1].toLocaleString()}
                </TableCell>
              </StyledTableRow>
            ))}
            {/* {data.map((item) => (
              <StyledTableRow key={item.plan}>
                <TableCell sx={{ pl: (theme) => theme.spacing(3) }}>
                  {item.plan}
                </TableCell>
                <TableCell align="right">{item.category}</TableCell>
                <TableCell align="left">
                  <Span sx={{ whiteSpace: "nowrap" }}>
                    {item.main_directive_percentage * 100}%
                  </Span>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ pr: (theme) => theme.spacing(3) }}
                >
                  KES{d3.sum(item.directives, (d) => d.budgets || 0)}
                </TableCell>
              </StyledTableRow>
            ))} */}
          </TableBody>
        </Table>
      </JumboScrollbar>
    </JumboCardQuick>
  );
};
/* Todo products prop define */
export default PerformanceList;
