import React from "react";
import ProjectItem from "./ProjectItem";
import { Box, Button, Chip, Stack, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { backend_url } from "app/services/constants";
import { useState } from "react";
import DirectivesFilter from "./directivesFilter";
import Div from "@jumbo/shared/Div";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import CircularProgress from "@mui/material/CircularProgress";
import { ThePETs } from "./ThePETs";
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PriorityProjects = ({ token }) => {
  const [directives, setDirectives] = useState([]);
  const [weights, setWeights] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [manifestoTrackingCategory, setManifestoTrackingCategory] =
    useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const limit = 30;
  const totalItems = weights.count;
  // const links = weights.links;
  const totalPages = Math.ceil(totalItems / limit);

  const handlePageChange = (offset) => {
    setCurrentPage(Math.ceil(offset / limit) + 1);
  };

  let query = useQuery();
  const cost = query.get("cost");
  const plan = query.get("plan");
  const ministry = query.get("ministry");
  const lead_agency = query.get("lead_agency");
  const county = query.get("county");
  const implementation_status = query.get("implementation_status");

  const [costMin, setCostMin] = useState(0);
  const [costMax, setCostMax] = useState(cost !== null ? cost : 900000000);

  const [costRange, setCostRange] = useState([
    0,
    cost !== null ? cost : 900000000,
  ]);

  const handleCostChange = (event, newValue) => {
    setCostRange(newValue);
  };

  const theme = useTheme();

  useEffect(() => {
    async function fetchData() {
      fetch(`${backend_url}/ppi/priorities/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setDirectives(data));
    }

    fetchData();
  }, [token]);

  const url = `${backend_url}/ppi/weights/?`;

  useEffect(() => {
    async function fetchData() {
      const offset = (currentPage - 1) * limit;
      const params = new URLSearchParams({ limit: limit, offset: offset });

      const addParam = (key, value) => {
        if (value) params.append(key, value);
      };

      addParam("summary_name__icontains", searchQuery);
      addParam("county__icontains", county);
      addParam("implementation_status__icontains", implementation_status);
      // addParam("budget__lt", costRange[1]);
      // addParam("budget__gt", costRange[0]);
      addParam("manifesto_tracking_category", manifestoTrackingCategory);
      addParam("manifesto_tracking_category__icontains", plan);
      addParam("ministry__icontains", ministry);
      addParam("lead_agency__icontains", lead_agency);

      fetch(`${url}?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setWeights(data));
    }

    fetchData();
  }, [token, setWeights, currentPage, url]);

  return (
    <Div>
      <JumboCardQuick
        title={
          <Div>
            <Typography
              variant={"h2"}
              mb={2}
              sx={{
                minWidth: 245,
                fontSize: "24px",
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  marginBottom: 2,
                },
              }}
            >
              President Priority Projects
            </Typography>

            <Stack direction={"row"} spacing={1}>
              <ThePETs
                token={token}
                setWeights={setWeights}
                currentPage={currentPage}
              />
            </Stack>
          </Div>
        }
        wrapperSx={{
          "&:last-child": {
            pb: 0,
          },
          "& .MuiCollapse-entered:last-child": {
            "& .MuiListItemButton-root": {
              borderBottom: 0,
              borderBottomColor: "transparent",
            },
          },
        }}
      />

      <Div sx={{ my: 1 }} />

      <JumboCardQuick
        title={
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexWrap: "wrap",
              },
            }}
          >
            <Typography
              variant={"h3"}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  marginBottom: 2,
                },
              }}
            >
              Directives
              <Chip
                sx={{ mx: 2 }}
                size={"medium"}
                label={weights.count}
                color="primary"
              />
            </Typography>
          </Typography>
        }
        action={
          <Button
            sx={{ m: 1, fontWeight: "medium" }}
            size={"medium"}
            color={open === true ? "primary" : "secondary"}
            variant="contained"
            onClick={() => (open === true ? setOpen(false) : setOpen(true))}
          >
            {open === true ? "Hide Filter Directives" : " Filter Directives"}
          </Button>
        }
        headerSx={{
          "& .MuiCardHeader-action": {
            my: -0.75,
          },
        }}
        wrapperSx={{
          p: 0,
          "&:last-child": {
            pb: 0,
          },
          "& .MuiCollapse-entered:last-child": {
            "& .MuiListItemButton-root": {
              borderBottom: 0,
              borderBottomColor: "transparent",
            },
          },
        }}
      >
        {open === true && weights.results !== undefined && (
          <DirectivesFilter
            costMin={costMin}
            costMax={costMax}
            setCostMin={setCostMin}
            setCostMax={setCostMax}
            cost={cost}
            costRange={costRange}
            handleCostChange={handleCostChange}
            weights={weights}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            open={open}
            setOpen={setOpen}
            token={token}
            setWeights={setWeights}
            setDirectives={setDirectives}
          />
        )}
      </JumboCardQuick>

      {weights.results !== undefined ? (
        weights.results.features === undefined &&
        weights.results.map(
          (directive) =>
            directive !== undefined && (
              <div key={directive.main_directive_id}>
                <ProjectItem
                  key={directive.main_directive_id}
                  dir_id={directive.main_directive_id}
                  percentage={directive.main_directive_percentage}
                  dir={directive}
                  sub={false}
                  token={token}
                />
              </div>
            )
        )
      ) : (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}

      {weights.results !== undefined && (
        <div className="my-16 mx-8">
          <Pagination
            totalItems={totalItems}
            limit={limit}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setCurrentPage={setCurrentPage}
            // previousPage={links.previous}
            // nextPage={links.next}
          />
        </div>
      )}
    </Div>
  );
};

export default PriorityProjects;
