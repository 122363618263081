import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import { backend_url } from "app/services/constants";
import NewActivity from "./NewActivity";
import PSActivityList from "app/pages/dashboards/PS/components/PSActivityList";
import { LeafLayers } from "app/pages/maps/LeafLayers";
import Span from "@jumbo/shared/Span";
import Comments from "../comments/Comments";
import LeadAgency from "app/pages/dashboards/pppi/newDirective/LeadAgency";
import ActualBudget from "app/pages/dashboards/pppi/newDirective/ActualBudget";

const DirDetails = ({ user, token }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [directive, setDirective] = useState([]);
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = React.useState([]);
  const [collaborating, setCollaborating] = React.useState([]);
  const [actBudget, setActBudget] = React.useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = activity.count;
  const links = activity.links;

  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const percentage_hash = window.location.hash;
  const percentage = percentage_hash.replace(/^#/, "");

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/ppi/details/?` +
          new URLSearchParams({
            directive_no: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setDirective(data));
    }

    fetchData();
  }, [token, id]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/ppi/activity/?` +
          new URLSearchParams({
            directive_no: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setActivity(data));
    }

    fetchData();
  }, [token, id]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/ppi/budget/?` +
          new URLSearchParams({
            directive_no: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setActBudget(data));
    }

    fetchData();
  }, [token, id]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/ppi/other_agencies/?` +
          new URLSearchParams({
            directive_no: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setCollaborating(data));
    }

    fetchData();
  }, [token, id]);

  const weightingList =
    activity.results !== undefined && activity.count > 0
      ? activity.results.features.map((item) => item.properties.weighting)
      : 1;

  const costList =
    activity.results !== undefined && activity.count > 0
      ? activity.results.features.map(
          (item) => item.properties.budget_activities
        )
      : 0;

  let totalActivityCost = 0;
  for (let i = 0; i < costList.length; i++) {
    totalActivityCost += costList[i];
  }

  // Convert string values to numbers
  const numericWeightingList =
    activity.results !== undefined && activity.count > 0
      ? weightingList.map((str) => parseFloat(str))
      : 1;

  // Calculate the sum of numericWeightingList
  const sum =
    activity.results !== undefined && activity.count > 0
      ? numericWeightingList.reduce((acc, value) => acc + value, 0)
      : 1;

  // Calculate the remaining weight
  const remainingWeight =
    activity.results !== undefined && activity.count > 0 ? 1 - sum : 1;

  const dir =
    directive.features !== undefined && directive.features[0].properties;

  const groupedActivities =
    activity.results !== undefined &&
    activity.results.features.reduce((result, act) => {
      const status = act.properties.status_activities;

      if (!result[status]) {
        result[status] = [];
      }

      result[status].push(act);
      return result;
    }, {});

  const notStartedActivities = groupedActivities.Not_Started || [];
  const onHoldActivities = groupedActivities.Over_Due || [];
  const inProgressActivities = groupedActivities.In_Progress || [];
  const completedActivities = groupedActivities.Completed || [];

  return (
    dir &&
    activity && (
      <div>
        <Card>
          <CardHeader
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Go back">
                  <IconButton onClick={() => navigate(-1)} sx={{ ml: -1.25 }}>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>

                <Typography variant="h4" sx={{ ml: 2, mt: 1 }}>
                  Directive Report
                </Typography>
              </div>
            }
            action={
              <Div sx={{ display: "flex", flexDirection: "row" }}>
                <Button
                  variant="outlined"
                  // color="inherit"
                  size="small"
                  component={Link}
                  to={`/directive/edit/${id}`}
                >
                  Edit Directive
                </Button>

                <Typography
                  sx={{
                    paddingLeft: "12px",
                    margin: "10px 6px 10px 0",
                    color: "text.secondary",
                  }}
                >
                  {" "}
                  {dir.responsibility}
                </Typography>
              </Div>
            }
          />

          {(user.designation === "PPI_1" || user.designation === "PPI_2") && (
            <LeadAgency token={token} dir_id={id} />
          )}

          {user.State_departments === "THE NATIONAL TREASURY" && (
            <ActualBudget token={token} dir_id={id} />
          )}

          <CardContent sx={{ p: 0 }}>
            <Div
              sx={{
                px: 3,
                display: "flex",
                minHeight: "100%",
                flexDirection: "column",
                minWidth: 0,
              }}
            >
              <Div sx={{ mb: 1 }}>
                <Typography variant={"h5"} component="h5" my={3}>
                  {dir.summary_name}
                </Typography>
                <Div
                  sx={{
                    display: "flex",
                  }}
                >
                  <Div sx={{ mt: 2, flex: 1 }}>
                    <List>
                      <ListItem disableGutters>
                        <Typography
                          variant={"body1"}
                          fontSize={18}
                          sx={{
                            display: "flex",
                            minWidth: 0,
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <Span sx={{ mr: 1, fontSize: 16, color: "text" }}>
                            {dir.ministry
                              .toLowerCase()
                              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                letter.toUpperCase()
                              )}
                            ,
                          </Span>

                          {dir.lead_agency
                            .toLowerCase()
                            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                              letter.toUpperCase()
                            )}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: 16 }}>
                          <b>The Plan:</b> {dir.manifesto_tracking_category},{" "}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: 16 }}>
                          <b>County:</b> {dir.county},{" "}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: 16 }}>
                          <b> Date Issued:</b> {dir.date_issue}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: 16 }}>
                          <b>Target Date:</b> {dir.target_date}
                        </Typography>
                      </ListItem>
                    </List>

                    <Typography sx={{ fontSize: 16, pt: 2 }}>
                      <b>Collaborating Agency / State Departments</b>{" "}
                    </Typography>

                    {/* {console.log("collaborating", collaborating)} */}
                    <List>
                      <ListItem>
                        <Typography sx={{ fontSize: 16 }}>
                          1. National Treasury
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: 16 }}>
                          2. Internal Security and National Administration
                        </Typography>
                      </ListItem>
                    </List>
                  </Div>
                </Div>
              </Div>
              <Div sx={{ flex: 1 }}>
                <Div
                  sx={{
                    position: "relative",
                    textAlign: "center",
                    mb: 2,
                    "&:after": {
                      display: "inline-block",
                      content: "''",
                      position: "absolute",
                      left: 0,
                      right: 0,
                      height: "1px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "divider",
                    },
                  }}
                >
                  <Chip
                    label={
                      dir.implementation_status === "In_Progress"
                        ? "In Progress"
                        : dir.implementation_status === "Not_Started"
                        ? "Not Started"
                        : dir.implementation_status === "Over_Due"
                        ? "Over Due"
                        : "Completed"
                    }
                    variant="outlined"
                    color={
                      percentage === 0
                        ? "error"
                        : percentage === 100
                        ? "success"
                        : percentage >= 1 && percentage <= 49
                        ? "warning"
                        : percentage >= 50 && percentage <= 99
                        ? "info"
                        : "primary"
                    }
                    sx={{
                      position: "relative",
                      zIndex: 1,
                      bgcolor: (theme) => theme.palette.background.paper,
                      borderColor: "divider",
                      borderRadius: 2,
                    }}
                  />

                  {dir.budget && (
                    <Chip
                      label={`Actual Cost: KES ${Number(
                        dir.budget
                      ).toLocaleString()}`}
                      variant="outlined"
                      color={"primary"}
                      sx={{
                        marginLeft: "1em",
                        position: "relative",
                        zIndex: 1,
                        bgcolor: (theme) => theme.palette.background.paper,
                        borderColor: "divider",
                        borderRadius: 2,
                        fontWeight: "bold",
                      }}
                    />
                  )}
                  {dir.budget && (
                    <Chip
                      label={`Proposed Cost: KES ${Number(
                        totalActivityCost
                      ).toLocaleString()}`}
                      variant="outlined"
                      color={"secondary"}
                      sx={{
                        marginLeft: "1em",
                        position: "relative",
                        zIndex: 1,
                        bgcolor: (theme) => theme.palette.background.paper,
                        borderColor: "divider",
                        borderRadius: 2,
                        fontWeight: "bold",
                      }}
                    />
                  )}
                </Div>

                {dir.full_description && (
                  <>
                    <Typography variant={"h4"} mt={4}>
                      Description
                    </Typography>

                    <Typography variant={"p"} mt={2}>
                      {dir.full_description}
                    </Typography>
                  </>
                )}

                {/* {dir.summary_name && (
                  <>
                    <Typography variant={"h4"} mt={4}>
                      Summary
                    </Typography>

                    <Typography variant={"p"} mt={2}>
                      {dir.summary_name}
                    </Typography>
                  </>
                )} */}

                {dir.upload && (
                  <Paper
                    elevation={3}
                    sx={{ mt: 4, p: 2, textAlign: "center", maxWidth: 480 }}
                  >
                    <Typography variant="h4" gutterBottom>
                      Uploaded Directive
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="center">
                      <Chip
                        label="Download/Preview Directive"
                        component="a"
                        href={`${backend_url}/${dir.upload}`}
                        target="_blank"
                        clickable
                        color="primary"
                        variant="outlined"
                        sx={{ p: 2, fontSize: "1.2em" }}
                      />
                    </Box>
                  </Paper>
                )}
              </Div>

              <Divider sx={{ my: 6 }} />

              <Div sx={{ mt: 2, display: "flex" }}>
                <Grid container spacing={1} mb={1}>
                  <Grid xs={6}>
                    <Typography variant={"h2"} mx={2}>
                      Activities
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    {user.designation === "PS" && (
                      <Div
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => setOpen(true)}
                          size="small"
                        >
                          New Activity
                        </Button>

                        <NewActivity
                          remainingWeight={remainingWeight}
                          activity={activity}
                          setActivity={setActivity}
                          token={token}
                          setOpen={setOpen}
                          open={open}
                          user={user}
                        />
                      </Div>
                    )}
                  </Grid>
                </Grid>
              </Div>

              {notStartedActivities.length > 0 && (
                <Div sx={{ mt: 2, mx: 2 }}>
                  <Typography variant={"h2"}>Not Started Activities</Typography>
                  {notStartedActivities.map((act) => (
                    <PSActivityList
                      dir_id={id}
                      token={token}
                      remainingWeight={remainingWeight}
                      activity={act}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      links={links}
                      view={"grid"}
                      user={user}
                      contact={"pdmis"}
                    />
                  ))}
                </Div>
              )}

              {inProgressActivities.length > 0 && (
                <Div sx={{ my: 4, mx: 2 }}>
                  <Typography variant={"h2"}>In Progress Activities</Typography>
                  {inProgressActivities.map((act) => (
                    <PSActivityList
                      dir_id={id}
                      token={token}
                      remainingWeight={remainingWeight}
                      activity={act}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      links={links}
                      view={"grid"}
                      user={user}
                      contact={"pdmis"}
                    />
                  ))}
                </Div>
              )}

              {onHoldActivities.length > 0 && (
                <Div sx={{ my: 4, mx: 2 }}>
                  <Typography variant={"h2"}>Over Due Activities</Typography>
                  {onHoldActivities.map((act) => (
                    <PSActivityList
                      dir_id={id}
                      token={token}
                      remainingWeight={remainingWeight}
                      activity={act}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      links={links}
                      view={"grid"}
                      user={user}
                      contact={"pdmis"}
                    />
                  ))}
                </Div>
              )}

              {completedActivities.length > 0 && (
                <Div sx={{ my: 4, mx: 2 }}>
                  <Typography variant={"h2"}>Completed Activities</Typography>
                  {completedActivities.map((act) => (
                    <PSActivityList
                      dir_id={id}
                      token={token}
                      remainingWeight={remainingWeight}
                      activity={act}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      links={links}
                      view={"grid"}
                      user={user}
                      contact={"pdmis"}
                    />
                  ))}
                </Div>
              )}
            </Div>

            {directive.features !== undefined && (
              <Div sx={{ mt: 2, mx: 2, mb: 8 }}>
                <Typography variant={"h2"} my={2} mx={2}>
                  Directive Location
                </Typography>

                <LeafLayers geom={directive.features[0].geometry} />
              </Div>
            )}

            <Comments token={token} scrollHeight={450} directive_no={id} />
          </CardContent>
        </Card>
      </div>
    )
  );
};

export default DirDetails;
