import React from "react";
import Typography from "@mui/material/Typography";
import { Box, LinearProgress } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Link } from "react-router-dom";

const ObjectCountOverDue = ({ overDue, totalDirectives }) => {
  return (
    <JumboCardQuick
      sx={{ height: 222, paddingTop: 3 }}
      title={
        <Box
          component={Link}
          sx={{ cursor: "pointer", textDecoration: "none" }}
          to="/directives?implementation_status=Over_Due&category=main_directive"
        >
          <Typography
            variant={"h3"}
            sx={{ fontSize: "48px" }}
            color={"primary"}
          >
            {Math.round((overDue / totalDirectives) * 100)}%
          </Typography>
          <Typography variant={"h6"} sx={{ fontSize: "16px" }}>
            {overDue} Over Due
          </Typography>
          <LinearProgress
            variant={"determinate"}
            color={"primary"}
            value={(overDue / totalDirectives) * 100}
            sx={{
              height: 6,
              borderRadius: 2,
              backgroundColor: "#E9EEEF",
            }}
          />
        </Box>
      }
      action={<HourglassEmptyIcon fontSize={"small"} color="primary" />}
      wrapperSx={{ textAlign: "center" }}
    ></JumboCardQuick>
  );
};

export default ObjectCountOverDue;
