import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";

const PercentageDistributionChart = ({ data }) => {
  const theme = useTheme();

  const groupedData = data.reduce((accumulator, item) => {
    const plan = item.plan;

    if (!accumulator[plan]) {
      accumulator[plan] = {
        notStarted: 0,
        ongoing: 0,
        completed: 0,
        overDue: 0,
        count: 0,
      };
    }
    // Increment the count and accumulate the values based on implementation status
    accumulator[plan].count++;

    item.directives.forEach((directive) => {
      const status = directive.implementation_status;
      if (status === "Not_Started") {
        accumulator[plan].notStarted++;
      } else if (status === "Ongoing") {
        accumulator[plan].ongoing++;
      } else if (status === "Completed") {
        accumulator[plan].completed++;
      } else if (status === "Over_Due") {
        accumulator[plan].overDue++;
      }
    });

    return accumulator;
  }, {});

  const averages = Object.keys(groupedData).map((plan) => ({
    plan,
    count: groupedData[plan].count,
    completed_full: groupedData[plan].completed,
    completed: (groupedData[plan].completed / groupedData[plan].count) * 100,
    ongoing_full: groupedData[plan].ongoing,
    ongoing: (groupedData[plan].ongoing / groupedData[plan].count) * 100,
    notStarted_full: groupedData[plan].notStarted,
    notStarted: (groupedData[plan].notStarted / groupedData[plan].count) * 100,
    overDue_full: groupedData[plan].overDue,
    overDue: (groupedData[plan].overDue / groupedData[plan].count) * 100,
  }));

  const sortedAverages = averages.sort((a, b) => b.completed - a.completed);

  useEffect(() => {
    // Your code to set up scales and draw the chart
  }, [data]);

  useEffect(() => {
    // Your code to set up scales and draw the chart
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active) {
      return <div></div>;
    }

    return (
      <div className="tooltip">
        <Card sx={{ m: 2 }}>
          <CardHeader
            title={
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {label}
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Total Directives: {payload[0].payload.count}
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Completed: {payload[0].payload.completed_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.completed)}%`})
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              In Progress: {payload[0].payload.ongoing_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.ongoing)}%`})
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Over Due: {payload[0].payload.overDue_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.overDue)}%`})
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Not Started: {payload[0].payload.notStarted_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.notStarted)}%`})
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  function yAxis(value) {
    let per = Math.round(value);

    if (per > 0) {
      return per + "%";
    }

    return 0;
  }

  return (
    <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
      <Typography fontWeight={"500"} variant={"h3"}>
        Percentage Distribution by Plan and Status
      </Typography>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={sortedAverages}
          margin={{ top: 20, right: 10, bottom: 150, left: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="plan"
            angle={-45}
            textAnchor="end"
            tickLine={false}
            tick={{ fontSize: 14, fill: "#999999" }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={yAxis}
            tick={{ fontSize: 14, fill: "#999999" }}
          />
          <Tooltip
            content={<CustomTooltip />}
            // cursor={<Cursor />}
          />

          <Bar dataKey="completed" fill={theme.palette.successLight.dark} />
          <Bar dataKey="ongoing" fill={theme.palette.primary.main} />
          <Bar dataKey="overDue" fill={theme.palette.primaryLight} />
          <Bar dataKey="notStarted" fill={theme.palette.error.light} />
        </BarChart>
      </ResponsiveContainer>
    </JumboCardQuick>
  );
};

export default PercentageDistributionChart;
