import React, { useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "../../../../styles/leaf.css";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List, ListItem, Typography } from "@mui/material";
import seq from "./counties.json";

function LocationDirectives({ data }) {
  const [onSelect, setOnSelect] = useState({});

  const countyCounts = {};
  const cleanCountyName = (county) => {
    return county
      ? county
          .trim()
          .replace(/["']/g, "")
          .replace(/\b\w/g, (char) => char.toUpperCase())
      : null;
  };

  // Iterate through the data and update the counts
  data.forEach((item) => {
    const county = cleanCountyName(item.county);
    // If the county is null, use a special key 'null'
    const key = county !== null ? county : "null";
    // If the key is not in the mapping, initialize count to 1, otherwise increment it
    countyCounts[key] = (countyCounts[key] || 0) + 1;
  });

  const quantileRanges = [
    { min: 1, max: 5 }, { min: 6, max: 10 }, { min: 11, max: 15 }, 
    { min: 16, max: 20 }, { min: 21, max: 25 }, { min: 26, max: 30 },
    { min: 31, max: 35 }, { min: 36, max: 40 }, { min: 41, max: 45 }, 
    { min: 46, max: 50 }, { min: 51, max: 55 }, { min: 56, max: 60 },
    { min: 61, max: 65 }, { min: 66, max: 70 }, { min: 71, max: 75 },
    { min: 76, max: 80 }, { min: 81, max: 85 }, { min: 86, max: 90 }, 
    { min: 91, max: 95 }, { min: 96, max: 500 }
  ];

  const getColor = (count) => {
    for (let i = 0; i < quantileRanges.length; i++) {
      const { min, max } = quantileRanges[i];
      if (count >= min && count <= max) {
        return spectralColors[i];
      }
    }
    return "grey"; // Default color if count does not fall within any quantile range
  };

  const highlightFeature = (e) => {
    const layer = e.target;
    const { County, name } = e.target.feature.properties;
    const directivesCount = countyCounts[County];

    setOnSelect({
      County: County,
      name: name,
      directivesCount: directivesCount,
    });

    layer.setStyle({
      weight: 2,
      color: "black",
      fillOpacity: 0.7,
    });
  };

  const resetHighlight = (e) => {
    setOnSelect({});
    e.target.setStyle(getStyle(e.target.feature));
  };

  const getStyle = (feature) => {
    const countyName = feature.properties.County;
    const count = countyCounts[countyName] || 0;
    const color = getColor(count);

    return {
      fillColor: color,
      weight: 1,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.7,
    };
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
    });
  };

  return (
    countyCounts && (
      <>
        <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
          <Typography fontWeight={"500"} variant={"h3"}>
            Directive Issued Locations
          </Typography>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "80vh",
            }}
          >
            {!onSelect.name && (
              <Typography fontWeight={"500"} variant="h4">
                Hover on each county for more details
              </Typography>
            )}
            {onSelect.name && (
              <List>
                <ListItem>
                  <Typography fontWeight={"500"} variant="h4">
                    {onSelect.name}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography fontWeight={"500"} variant="h4">
                    Directives:{" "}
                    {countyCounts[onSelect.County] !== undefined
                      ? countyCounts[onSelect.County]
                      : 0}
                  </Typography>
                </ListItem>
              </List>
            )}

            <MapContainer
              style={{
                position: "absolute",
              }}
              center={[0, 37]}
              maxBoundsViscosity={1.0}
              zoom={6}
              zoomControl={false}
              scrollWheelZoom={true}
              dragging={true}
              touchZoom={true}
              doubleClickZoom={true}
              boxZoom={true}
              keyboard={true}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png"
              />

              <GeoJSON
                data={seq}
                style={getStyle}
                onEachFeature={onEachFeature}
              />
            </MapContainer>
          </div>
        </JumboCardQuick>
      </>
    )
  );
}

export default LocationDirectives;
const spectralColors = [
  "#5E4FA2", "#4881B6", "#2D9BC1", "#43BCA3", "#79CD98", 
  "#BBDF79", "#F8F75A", "#F3E442", "#F1B226", "#D01C8B",
  "#762A83", "#7B3294", "#8073AC", "#92C5DE", "#ABDDA4",
  "#E6F598", "#FFFFBF", "#FEE08B", "#FDAE61", "#F46D43"
];

// const spectralColors = [
//   "#FFFFCC", "#FFEDA0", "#FED976", "#FEB24C", "#FD8D3C",
//   "#FC4E2A", "#E31A1C", "#BD0026", "#800026"
// ];
