import React, { useState } from "react";
import { min_state_inst } from "app/shared/Components/institutions";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import _ from "@lodash";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { backend_url } from "app/services/constants";
import { useFormik } from "formik";
import { format } from "date-fns";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import "../../../../../styles/leaf.css";
import { kenya_counties } from "app/shared/Components/kenya_counties";

const NewDirective = ({ user, token }) => {
  const [loading, setLoading] = useState(false);
  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [filteredMinistry, setFilteredMinistry] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });

  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    setMarkerPosition({ lat, lng });
  };

  const handleTextFieldChange = (field, value) => {
    setMarkerPosition((prevPosition) => ({
      ...prevPosition,
      [field]: parseFloat(value) || 0, // Parse the value to a float or set to 0 if not valid
    }));
  };

  const MapClickHandler = () => {
    const map = useMapEvents({
      click: handleMapClick,
    });

    return null; // Return null as we don't need to render anything for the MapClickHandler
  };

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const handleCountyChange = (newValue) => {
    const selectedCounty = newValue;

    const filteredCounty = kenya_counties.filter(
      (item) => item.Ministry === selectedCounty
    );

    setSelectedCounty(filteredCounty);
    setSelectedCounty(selectedCounty);
  };

  const counties = _.uniqWith(
    kenya_counties.map((county) => county.County),
    _.isEqual
  );

  const handleMinistryChange = (newValue) => {
    const selectedMinistry = newValue;

    const filteredMinistries = min_state_inst.filter(
      (item) => item.Ministry === selectedMinistry
    );

    setFilteredMinistry(filteredMinistries);
    setSelectedMinistry(selectedMinistry);
  };

  const ministries = _.uniqWith(
    min_state_inst.map((min) => min.Ministry),
    _.isEqual
  );

  const department =
    filteredMinistry &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const validationSchema = yup.object({
    summary_name: yup.string().required("Please enter the directive details."),
    full_description: yup
      .string()
      .notRequired("Please enter the full description."),
    county: yup
      .string()
      .required()
      .oneOf(counties !== false ? counties : [], "Please select q county"),
    beta_pillar: yup
      .string()
      .required()
      .oneOf(
        [
          "Agriculture",
          "Micro Small and Medium Sized Enterprise Economy",
          "Affordable Housing",
          "Universal Healthcare Coverage",
          "Digital Superhighway and Creative Economy",
          "Others",
        ],
        "Please select the beta pillar"
      ),
    manifesto_tracking_category: yup
      .string()
      .required()
      .nullable()
      .oneOf(
        [
          "None",
          "Agriculture",
          "Transforming MSMEs",
          "Affordable Housing",
          "Health Care",
          "Digital Superhighway and Creative Economy",
          "Infrastructure",
          "Manufacturing",
          "Service Economy",
          "Education",
          "Environment and Climate Change",
          "Women",
          "Social Protection",
          "Governance",
          "Devolution",
          "Security Services",
          "Diaspora",
        ],
        "Please select the plan"
      ),
    directive_category: yup
      .string()
      .required()
      .oneOf(
        ["Main Directive", "Sub Directive"],
        "Please select the beta pillar"
      ),
    ministry: yup
      .string()
      .required("You must enter your ministry")
      .oneOf(
        ministries !== false ? ministries : [],
        "Please select the ministry"
      ),
    State_departments: yup
      .string()
      .required("You must enter your state department")
      .oneOf(
        department !== false ? department : [],
        "Please select the state department"
      ),
    budget: yup.number().required("Please enter the budget."),
    date_issue: yup
      .date()
      .required("You must enter date of program")
      .default(() => new Date()),
    target_date: yup.mixed().notRequired(),
    directive_upload: yup.mixed().notRequired(),
  });

  const defaultValues = {
    summary_name: "",
    county: "",
    beta_pillar: "",
    manifesto_tracking_category: "",
    directive_category: "",
    ministry: [],
    State_departments: "None",
    full_description: "",
    date_issue: null,
    target_date: null,
    directive_upload: null,
    budget: 0,
  };

  function submit({
    directive_upload,
    summary_name,
    county,
    beta_pillar,
    manifesto_tracking_category,
    directive_category,
    ministry,
    State_departments,
    date_issue,
    full_description,
    target_date,
    budget,
  }) {
    console.log("target_date", target_date);

    const dirDetails = {
      upload: directive_upload,
      summary_name,
      county,
      beta_pillar,
      manifesto_tracking_category,
      // manifesto_tracking_category:
      //   manifesto_tracking_category === "None"
      //     ? null
      //     : manifesto_tracking_category,
      directive_category,
      date_issue: format(new Date(date_issue), "yyyy-MM-dd"),
      target_date:
        target_date === null || target_date === undefined
          ? null // format(new Date("1970-01-01"), "yyyy-MM-dd")
          : format(new Date(target_date), "yyyy-MM-dd"),
      lead_agency: State_departments === "None" ? null : State_departments,
      ministry: ministry === "None" ? null : ministry,
      brag_status: "#F5A6",
      full_description,
      budget,
      location: `POINT(${markerPosition.lng} ${markerPosition.lat})`,
      // responsibility: 'Dr.',
    };

    axios
      .put(
        `${backend_url}/ppi/directive/`,
        dirDetails,
        {
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Directive added successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
        navigate("/directives");
        // trip_id === undefined ? window.location.reload() : handleNext();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: { defaultValues },
    validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);
  };

  console.log("showErrors", showErrors);

  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant={"h2"} mb={3}>
        Create a new Directive
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
            borderRadius: "8px",
            width: "50%",
            height: 100,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            cursor: "pointer",
            boxShadow: 3,
            "&:hover": {
              boxShadow: 6,
            },
            mb: 3,
            mt: 1,
          }}
          component="label"
          htmlFor="button_directive_upload"
        >
          <input
            accept=".pdf, .png, .jpg, .jpeg"
            style={{ display: "none" }}
            id="button_directive_upload"
            type="file"
            onChange={(e) => {
              formik.setFieldValue("directive_upload", e.target.files[0]);
            }}
          />
          {formik.values.directive_upload ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <PictureAsPdfIcon sx={{ color: "action", mr: 1 }} />
              <Typography variant="body2" component="span">
                {formik.values.directive_upload.name}
              </Typography>
            </Box>
          ) : (
            <>
              <IconButton>
                <FileUploadIcon sx={{ color: "action" }} />
              </IconButton>
              <Typography variant="caption" component="span" sx={{ mt: 1 }}>
                Upload Directive
              </Typography>
            </>
          )}
          <FormHelperText
            sx={{
              position: "absolute",
              bottom: -20,
              textAlign: "center",
              width: "100%",
            }}
          >
            <span className="flex flex-col text-red-500">
              {formik.touched.directive_upload &&
                formik.errors.directive_upload}
            </span>
          </FormHelperText>
        </Box>

        <Div sx={{ mb: 2, mt: 1 }}>
          <TextField
            fullWidth
            id="summary_name"
            name="summary_name"
            label="Summary"
            multiline={3}
            minRows={2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.summary_name}
            error={
              (showErrors || formik.touched.summary_name) &&
              Boolean(formik.errors.summary_name)
            }
            helperText={
              (showErrors || formik.touched.summary_name) &&
              formik.errors.summary_name
            }
          />
        </Div>

        <Div sx={{ mb: 2, mt: 1 }}>
          <TextField
            fullWidth
            id="full_description"
            name="full_description"
            label="Full Description"
            multiline={3}
            minRows={3}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.full_description}
            error={
              (showErrors || formik.touched.full_description) &&
              Boolean(formik.errors.full_description)
            }
            helperText={
              (showErrors || formik.touched.full_description) &&
              formik.errors.full_description
            }
          />
        </Div>

        <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
          <Autocomplete
            sx={{ paddingRight: "10px" }}
            fullWidth
            id="county"
            name="county"
            options={Array.from(
              new Set(kenya_counties.map((item) => item.County))
            )
              .map((county) => county)
              .sort()}
            value={formik.values.county}
            onChange={(e, newValue) => {
              formik.setFieldValue("county", newValue);
              handleCountyChange(newValue);
            }}
            onBlur={formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                label="County"
                error={
                  (showErrors || formik.touched.county) &&
                  Boolean(formik.errors.county)
                }
                helperText={
                  (showErrors || formik.touched.county) && formik.errors.county
                }
              />
            )}
          />
        </Div>

        <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
          <FormControl sx={{ mb: 2 }} fullWidth>
            <InputLabel id="directive_category">Directive Category</InputLabel>
            <Select
              labelId="directive_category"
              id="directive_category"
              name="directive_category"
              label="Directive Category"
              value={formik.values.directive_category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (showErrors || formik.touched.directive_category) &&
                Boolean(formik.errors.directive_category)
              }
            >
              <MenuItem value={"Main Directive"}>Main Directive</MenuItem>
              <MenuItem value={"Sub Directive"}>Sub Directive</MenuItem>
            </Select>
            {(showErrors || formik.touched.directive_category) &&
              formik.errors.directive_category && (
                <FormHelperText>
                  {formik.errors.directive_category}
                </FormHelperText>
              )}
          </FormControl>

          <FormControl sx={{ mb: 2, ml: 2 }} fullWidth>
            <InputLabel id="beta_pillar">Beta Pillar</InputLabel>
            <Select
              labelId="beta_pillar"
              id="beta_pillar"
              name="beta_pillar"
              label="Beta Pillar"
              value={formik.values.beta_pillar}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (showErrors || formik.touched.beta_pillar) &&
                Boolean(formik.errors.beta_pillar)
              }
            >
              <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
              <MenuItem
                value={"Micro Small and Medium Sized Enterprise Economy"}
              >
                Micro Small and Medium Sized Enterprise Economy
              </MenuItem>
              <MenuItem value={"Affordable Housing"}>
                Affordable Housing
              </MenuItem>
              <MenuItem value={"Universal Healthcare Coverage"}>
                Universal Healthcare Coverage
              </MenuItem>
              <MenuItem value={"Digital Superhighway and Creative Economy"}>
                Digital Superhighway and Creative Economy
              </MenuItem>
              <MenuItem value={"Others"}>Other</MenuItem>
            </Select>
            {(showErrors || formik.touched.beta_pillar) &&
              formik.errors.beta_pillar && (
                <FormHelperText>{formik.errors.beta_pillar}</FormHelperText>
              )}
          </FormControl>

          <FormControl sx={{ mb: 2, ml: 2 }} fullWidth>
            <InputLabel id="manifesto_tracking_category">The Plan</InputLabel>
            <Select
              labelId="manifesto_tracking_category"
              id="manifesto_tracking_category"
              name="manifesto_tracking_category"
              label="The Plan"
              value={formik.values.manifesto_tracking_category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (showErrors || formik.touched.manifesto_tracking_category) &&
                Boolean(formik.errors.manifesto_tracking_category)
              }
            >
              <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
              <MenuItem value={"Transforming MSMEs"}>
                Transforming MSMEs
              </MenuItem>
              <MenuItem value={"Affordable Housing"}>
                Affordable Housing
              </MenuItem>
              <MenuItem value={"Health Care"}>Health Care</MenuItem>
              <MenuItem value={"Digital Superhighway and Creative Economy"}>
                Digital Superhighway and Creative Economy
              </MenuItem>
              <MenuItem value={"Infrastructure"}>Infrastructure</MenuItem>
              <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
              <MenuItem value={"Service Economy"}>Service Economy</MenuItem>
              <MenuItem value={"Education"}>Education</MenuItem>
              <MenuItem value={"Environment and Climate Change"}>
                Environment and Climate Change
              </MenuItem>
              <MenuItem value={"Women"}>Women</MenuItem>
              <MenuItem value={"Social Protection"}>Social Protection</MenuItem>
              <MenuItem value={"Governance"}>Governance</MenuItem>
              <MenuItem value={"Devolution"}>Devolution</MenuItem>
              <MenuItem value={"Security Services"}>Security Services</MenuItem>
              <MenuItem value={"Diaspora"}>Diaspora</MenuItem>
            </Select>
            {(showErrors || formik.touched.manifesto_tracking_category) &&
              formik.errors.manifesto_tracking_category && (
                <FormHelperText>
                  {formik.errors.manifesto_tracking_category}
                </FormHelperText>
              )}
          </FormControl>
        </Div>

        {min_state_inst && (
          <Div sx={{ mb: 2, mt: 1 }}>
            <Autocomplete
              fullWidth
              id="ministry"
              name="ministry"
              options={Array.from(
                new Set(min_state_inst.map((item) => item.Ministry))
              )
                .map((ministry) => ministry)
                .sort()}
              value={formik.values.ministry}
              onChange={(e, newValue) => {
                formik.setFieldValue("ministry", newValue);
                handleMinistryChange(newValue);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ministry"
                  error={
                    (showErrors || formik.touched.ministry) &&
                    Boolean(formik.errors.ministry)
                  }
                  helperText={
                    (showErrors || formik.touched.ministry) &&
                    formik.errors.ministry
                  }
                />
              )}
            />
          </Div>
        )}

        {selectedMinistry && (
          <Div sx={{ mb: 2, mt: 1 }}>
            <Autocomplete
              fullWidth
              id="State_departments"
              name="State_departments"
              options={department.sort()}
              value={formik.values.State_departments}
              onChange={(e, newValue) => {
                formik.setFieldValue("State_departments", newValue);
                // handleStateChange(newValue);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State Departments"
                  error={
                    (showErrors || formik.touched.State_departments) &&
                    Boolean(formik.errors.State_departments)
                  }
                  helperText={
                    (showErrors || formik.touched.State_departments) &&
                    formik.errors.State_departments
                  }
                />
              )}
            />
          </Div>
        )}

        <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
          <Div sx={{ mb: 2, mt: 1, display: "flex", width: "50%" }}>
            <TextField
              sx={{ mr: 2 }}
              fullWidth
              id="budget"
              name="budget"
              label="Cost (KES)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.budget}
              error={
                (showErrors || formik.touched.budget) &&
                Boolean(formik.errors.budget)
              }
              helperText={
                (showErrors || formik.touched.budget) && formik.errors.budget
              }
            />
          </Div>

          <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ mr: 2 }}
                fullWidth
                id="date_issue"
                name="date_issue"
                label="Date of Issue"
                value={formik.values.date_issue}
                onChange={(date) => formik.setFieldValue("date_issue", date)}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      (showErrors || formik.touched.date_issue) &&
                      Boolean(formik.errors.date_issue)
                    }
                    helperText={
                      (showErrors || formik.touched.date_issue) &&
                      formik.errors.date_issue
                    }
                  />
                )}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                id="target_date"
                name="target_date"
                label="Target Date"
                value={formik.values.target_date}
                onChange={(date) => formik.setFieldValue("target_date", date)}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      (showErrors || formik.touched.target_date) &&
                      Boolean(formik.errors.target_date)
                    }
                    helperText={
                      (showErrors || formik.touched.target_date) &&
                      formik.errors.target_date
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Div>
        </Div>

        <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
          <TextField
            fullWidth
            sx={{ mr: 2 }}
            id="latitude"
            name="latitude"
            label="Latitude"
            onChange={(e) => handleTextFieldChange("lat", e.target.value)}
            value={markerPosition.lat}
            type="number"
            step="any" // Allow decimal values
          />

          <TextField
            fullWidth
            id="longitude"
            name="longitude"
            label="Longitude"
            onChange={(e) => handleTextFieldChange("lng", e.target.value)}
            value={markerPosition.lng}
            type="number"
            step="any" // Allow decimal values
          />
        </Div>

        <Div sx={{ mb: 2, mt: 1 }}>
          <MapContainer
            center={[0, 37]}
            zoom={6}
            style={{ height: "400px", width: "100%" }}
            onClick={handleMapClick}
            scrollWheelZoom={true}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            <MapClickHandler />

            <Marker position={[markerPosition.lat, markerPosition.lng]}>
              <Popup>
                Marker Position: ({markerPosition.lat}, {markerPosition.lng})
              </Popup>
            </Marker>
          </MapContainer>
        </Div>

        <Button
          fullWidth
          type="submit"
          color="secondary"
          variant="contained"
          size="large"
          sx={{ my: 3 }}
          loading={loading}
          onClick={() => {
            // navigate("/");
            handleFieldErrors();
            // setLoading(true);
          }}
          // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
        >
          Next
        </Button>
      </form>
    </Paper>
  );
};

export default NewDirective;
