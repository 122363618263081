import React from "react";
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import Span from "@jumbo/shared/Span";
import moment from "moment";
import ListItemButton from "@mui/material/ListItemButton";

const CommentItem = ({ item }) => {
  return (
    <ListItemButton
      component={"li"}
      alignItems={"flex-start"}
      sx={{
        borderBottom: 1,
        borderBottomColor: "divider",
        px: 3,

        "&:hover .ListItemIcons": {
          opacity: 1,
        },
      }}
    >
      <ListItemAvatar sx={{ minWidth: 68 }}>
        <Avatar
          sx={{ width: 48, height: 48 }}
          alt={item.message}
          // src={item.user.profile_pic}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography component={"div"}>
            <Typography variant={"body1"} mb={1}>
              <Span sx={{ color: "primary.main" }}>{item.first_name} {item.last_name}</Span>
              {" commented on "}
              <Span sx={{ color: "primary.main" }}>{moment(item.updated_at).format("DD MMMM")}</Span>
            </Typography>
          </Typography>
        }
        secondary={
          <Typography variant={"body1"} sx={{ color: "text.secondary" }}>
            {item.message}
          </Typography>
        }
      />
      <ListItemText sx={{ ml: 2, flexShrink: 0, mt: 1 }}>
        <Typography variant={"body1"} color={"text.secondary"} fontSize={12}>
          {moment(item.updated_at).format("mm")} {" minute ago"}
        </Typography>
      </ListItemText>
      {/* <ListItemIcon
        className={"ListItemIcons"}
        sx={{
          position: "absolute",
          right: 24,
          top: 36,
          transition: "all 0.2s",
          opacity: 0,
        }}
      >
        <Fab size="small" color={"primary"}>
          <CheckIcon />
        </Fab>
        <Fab size="small" sx={{ ml: 2 }}>
          <CloseIcon />
        </Fab>
      </ListItemIcon> */}
    </ListItemButton>
  );
};
/* Todo item prop define */
export default CommentItem;
