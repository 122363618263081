import React from "react";
import Typography from "@mui/material/Typography";
import { Box, LinearProgress } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { Link } from "react-router-dom/dist";

const ObjectCountOrders = ({ totalDirectives }) => {
  return (
    <JumboCardQuick
      sx={{
        height: 222,
        paddingTop: 3,
      }}
      title={
        <Box
          component={Link}
          to="/directives"
          sx={{ cursor: "pointer", textDecoration: "none" }}
        >
          <Typography
            variant={"h3"}
            sx={{ fontSize: "48px" }}
            color={"primary"}
          >
            {totalDirectives}
          </Typography>
          <Typography variant={"h6"} sx={{ fontSize: "16px" }}>
            Total Main Directives
          </Typography>
          <LinearProgress
            variant={"determinate"}
            color={"warning"}
            value={100}
            sx={{
              height: 6,
              borderRadius: 2,
              backgroundColor: "#E9EEEF",
            }}
          />
        </Box>
      }
      action={<FolderCopyIcon fontSize={"large"} color="primary" />}
      wrapperSx={{ textAlign: "center" }}
    ></JumboCardQuick>
  );
};

export default ObjectCountOrders;
