import React from "react";
import Avatar from "@mui/material/Avatar";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import his_excellency from "../../../../images/his_excellency.jpg";
import { backend_url } from "app/services/constants";

const AuthUserDropdown = ({ user }) => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();

  const onLogout = () => {
    navigate("/logout");
  };

  return (
    user !== undefined && (
      <ThemeProvider theme={theme}>
        <JumboDdPopover
          triggerButton={
            <Avatar
              src={
                user.designation === "HE"
                  ? his_excellency
                  : `${backend_url}${user.profile_photo}`
              }
              sizes={"small"}
              sx={{ boxShadow: 25, cursor: "pointer" }}
            />
          }
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              minWidth: "8em",
              p: (theme) => theme.spacing(2.5),
            }}
          >
            <Avatar
              src={
                user.designation === "HE"
                  ? his_excellency
                  : `${backend_url}${user.profile_photo}`
              }
              alt={
                user.designation === "HE"
                  ? "His Excellency"
                  : `${backend_url}${user.profile_photo}`
              }
              sx={{ width: 60, height: 60, mb: 2 }}
            />
            <Typography variant={"h5"}>
              {user.designation === "HE"
                ? "His Excellency"
                : (user.first_name, user.last_name)}
            </Typography>
            <Typography variant={"body1"} color="text.secondary">
              {user.designation !== "HE" && user.email}
            </Typography>
          </Div>
          <Divider />
          <nav>
            <List disablePadding sx={{ pb: 1, textDecoration: "none" }}>
              <ListItemButton component={Link} to="/profile">
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <PersonOutlineIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{ textDecoration: "none" }}
                  primary="Profile"
                />
              </ListItemButton>
              <ListItemButton component={Link} to="/profile">
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{ textDecoration: "none" }}
                  primary="Edit Profile"
                />
              </ListItemButton>
              <ListItemButton onClick={onLogout}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ my: 0 }} />
              </ListItemButton>
            </List>
          </nav>
        </JumboDdPopover>
      </ThemeProvider>
    )
  );
};

export default AuthUserDropdown;
