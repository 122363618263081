import { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { backend_url } from "app/services/constants";
import Reports from "./Reports";

const ReportsMain = ({ token, user }) => {
  const [weights, setWeights] = useState([]);
  const [resp, setResp] = useState(false);
  const [counties, setCounties] = useState(false);

  const isCS = user.designation === "CS" ? true : false;
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `${backend_url}/ppi/weights?limit=1999`;

        // If user is CS, include ministry filter in the URL
        if (isCS) {
          const encodedMinistry = encodeURIComponent(user.ministry).replace(
            /%20/g,
            "+"
          );
          url += `?ministry=${encodedMinistry}`;
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setWeights(data);
        setResp(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error, e.g., display a message to the user
      }
    };

    fetchData();
  }, [token, setWeights, isCS, user.ministry]);

  const weightsResults = weights.results;

  return resp === false ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Div>
      {weightsResults !== undefined && (
        <Reports
          counties={counties}
          setCounties={setCounties}
          user={user}
          resp={resp}
          token={token}
          weightsResults={weightsResults}
        />
      )}
    </Div>
  );
};

export default ReportsMain;
