import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";

// Directive performance per plan

const CustomBarChart = ({ data }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [activeIndex, setActiveIndex] = useState(null);
  const theme = useTheme();

  const filteredData =
    selectedCategory === "All"
      ? data
      : data.filter((d) => d.plan === selectedCategory);

  filteredData.sort(
    (a, b) =>
      b.main_directive_percentage * 100 - a.main_directive_percentage * 100
  );

  const groupedData = filteredData.reduce((accumulator, item) => {
    const plan = item.plan;

    if (!accumulator[plan]) {
      accumulator[plan] = {
        totalPercentage: 0,
        count: 0,
      };
    }

    // Increment the count and accumulate the main_directive_percentage
    accumulator[plan].count++;
    accumulator[plan].totalPercentage += item.main_directive_percentage * 100;

    return accumulator;
  }, {});

  // Calculate averages for each plan
  const averages = Object.keys(groupedData).map((plan) => ({
    plan,
    averageMainDirectivePercentage:
      groupedData[plan].totalPercentage / groupedData[plan].count,
  }));

  // sort by descending
  const sortedAverages = averages.sort((a, b) => {
    return b.averageMainDirectivePercentage - a.averageMainDirectivePercentage;
  });

  const handleBarMouseEnter = (data, index) => {
    setActiveIndex(index);
  };

  const handleBarMouseLeave = () => {
    setActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active) {
      return <div></div>;
    }

    return (
      <div className="tooltip">
        <Card sx={{ m: 2 }}>
          <CardHeader title={label} />
          <CardContent sx={{ pt: 2 }}>
            <Typography variant="p">Completed Percentage</Typography>
            <Typography variant="p">
              {" "}
              {` ${Math.round(
                payload[0].payload.averageMainDirectivePercentage
              )}`}
              %
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  function yAxis(value) {
    let per = Math.round(value);

    if (per > 0) {
      return per + "%";
    }

    return 0;
  }

  const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    const isActive = props.index === activeIndex;

    // Check if payload is defined and has elements
    const expValue =
      payload && payload.length > 0 ? payload[0].payload.exp : "";

    return (
      <g>
        {isActive && (
          <rect
            x={x}
            y={y - 20}
            rx="5"
            width={width}
            height={height}
            fill={theme.palette.primary.main}
          />
        )}

        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={isActive ? theme.palette.primary.main : fill}
          // stroke={isActive ? "red" : "none"}
          // strokeWidth={isActive ? 2 : 0}
        />

        {isActive && (
          <text
            x={x + width / 2}
            y={y + height + 17}
            textAnchor="middle"
            fill="#191818"
            fontSize="12"
            fontWeight="700"
          >
            {expValue}
          </text>
        )}
        {/* {isActive && (
          <text
            x={x + width / 2}
            y={y + height + 47}
            textAnchor="middle"
            fill="#191818"
            fontSize="12"
            fontWeight="700"
          >
          </text>
        )} */}
      </g>
    );
  };

  return (
    <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
        }}
      >
        <Typography fontWeight={"500"} variant={"h3"}>
          Overall performance per plan
        </Typography>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={sortedAverages}
            margin={{ top: 20, right: 20, bottom: 120, left: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="plan"
              angle={-45}
              textAnchor="end"
              tickLine={false}
              tick={{ fontSize: 14, fill: "#999999" }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickFormatter={yAxis}
              tick={{ fontSize: 14, fill: "#999999" }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="averageMainDirectivePercentage"
              fill={theme.palette.primary.main}
              onMouseEnter={handleBarMouseEnter}
              onMouseLeave={handleBarMouseLeave}
              shape={<CustomBar />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </JumboCardQuick>
  );
};

export default CustomBarChart;
