import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import _ from "@lodash";
import { min_state_inst } from "app/shared/Components/institutions";
import { useState } from "react";
import { useEffect } from "react";
import { backend_url } from "app/services/constants";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const DirectivesFilter = ({
  costMin,
  costMax,
  setCostMin,
  setCostMax,
  setOpen,
  weights,
  setWeights,
  token,
  currentPage,
  setCurrentPage,
}) => {
  const [selectedMinistry, setSelectedMinistry] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [betaPillar, setBetaPillar] = useState(null);
  const [filteredMinistry, setFilteredMinistry] = useState([]);
  const [filteredState, setFilteredState] = useState(null);
  const [manifestoTrackingCategory, setManifestoTrackingCategory] =
    useState(null);
  const [directiveCategory, setDirectiveCategory] = useState(null);
  const [dateIssue, setDateIssue] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [location, setLocation] = useState(null);
  const [implementationStatus, setImplementationStatus] = useState(null);

  const handleMinistryChange = (newValue) => {
    const selectedMinistry = newValue;

    // Filter the data based on the selected Ministry
    const filteredMinistries = min_state_inst.filter(
      (item) => item.Ministry === selectedMinistry
    );
    setFilteredMinistry(filteredMinistries);
    setSelectedMinistry(selectedMinistry);
  };

  const handleStateChange = (newValue) => {
    const selectedState = newValue;

    // Filter the data based on the selected Ministry
    const filteredStates =
      selectedState === "None"
        ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
        : min_state_inst.filter(
            (item) => item.State_Departments === selectedState
          );

    setFilteredState(filteredStates);
    setSelectedState(selectedState);
  };

  const department =
    filteredMinistry &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const queryParams = new URLSearchParams();

  const clearFilters = () => {
    setSelectedMinistry("");
    setFilteredState("");
    setSelectedState("");
    setBetaPillar("");
    setManifestoTrackingCategory("");
    setDirectiveCategory("");
    setDateIssue(null);
    setTargetDate(null);
    setLocation("");
    setImplementationStatus("");
    setCostMax(0);
    setCostMin(0);
    setOpen(false);
  };

  const filters = {
    budget__gt: costMin,
    budget__lt: costMax,
    ministry: selectedMinistry,
    lead_agency: selectedState,
    beta_pillar: betaPillar,
    manifesto_tracking_category: manifestoTrackingCategory,
    directive_category: directiveCategory,
    date_issue: dateIssue,
    target_date: targetDate,
    implementation_status: implementationStatus,
  };

  // Iterate through the filters and add them to the URLSearchParams
  Object.entries(filters).forEach(([filterName, filterValue]) => {
    if (filterValue) {
      queryParams.append(filterName, filterValue);
    }
  });

  const designation = localStorage.getItem("designation");

  const params = new URLSearchParams({ ppi: currentPage });

  const url = `${backend_url}/ppi/weights/?${params}&${queryParams.toString()}`;

  if (weights.count < 50) {
    setCurrentPage(1);
  }

  useEffect(() => {
    async function fetchData() {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setWeights(data));
    }

    fetchData();
  }, [token, setWeights, url]);

  return (
    <Div>
      <Box sx={{ mx: 4 }}>
        <Typography
          variant={"h5"}
          sx={{
            minWidth: 245,
            minWidth: "100%",
            fontWeight: "bold",
          }}
        >
          Filter directives by
        </Typography>
        <Div>
          <Box mt={2}>
            <Typography gutterBottom>Cost Range</Typography>
            <Grid container spacing={2} alignItems="center">
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="Cost-min"
                  label="Cost Min"
                  placeholder="Cost Min"
                  value={costMin}
                  onChange={(e) => setCostMin(e.target.value)}
                  type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="Cost-max"
                  label="Cost Max"
                  placeholder="Cost Max"
                  value={costMax}
                  onChange={(e) => setCostMax(e.target.value)}
                  type="number"
                />
              </Grid> */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="Cost-min"
                  label="Cost Min"
                  placeholder="Cost Min"
                  value={costMin}
                  onChange={(e) => {
                    setCostMin(e.target.value);
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="Cost-max"
                  label="Cost Max"
                  placeholder="Cost Max"
                  value={costMax}
                  onChange={(e) => {
                    setCostMax(e.target.value);
                  }}
                  type="number"
                />
              </Grid>
              {/* <Grid item xs={4}>
                <Slider
                  value={costRange}
                  onChange={handleCostChange}
                  valueLabelDisplay="auto"
                  min={costRange[0]}
                  max={costRange[1]}
                  step={10}
                  marks={[
                    {
                      value: costRange[0],
                      label: costRange[0],
                    },
                    {
                      value: costRange[1],
                      label: costRange[1],
                    },
                  ]}
                />
              </Grid> */}
            </Grid>
          </Box>

          <Box display="flex" flexDirection="row" gap={2} mt={2}>
            <FormControl fullWidth>
              <InputLabel>Beta Pillar</InputLabel>
              <Select
                sx={{ my: 1 }}
                labelId="beta_pillar"
                id="beta_pillar"
                name="beta_pillar"
                label="Beta Pillar"
                value={betaPillar}
                onChange={(e) => {
                  setBetaPillar(e.target.value);
                }}
              >
                <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                <MenuItem value={"Education"}>Education</MenuItem>
                <MenuItem value={"Affordable Housing"}>
                  Affordable Housing
                </MenuItem>
                <MenuItem value={"Universal Healthcare Coverage"}>
                  Universal Healthcare Coverage
                </MenuItem>
                <MenuItem value={"Biodiversity"}>Biodiversity</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>The Plan</InputLabel>
              <Select
                sx={{ my: 1 }}
                labelId="manifesto_tracking_category"
                id="manifesto_tracking_category"
                name="manifesto_tracking_category"
                label="The Plan"
                value={manifestoTrackingCategory}
                onChange={(e) => {
                  setManifestoTrackingCategory(e.target.value);
                }}
              >
                <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                <MenuItem value={"Transforming MSMEs"}>
                  Transforming MSMEs
                </MenuItem>
                <MenuItem value={"Affordable Housing"}>
                  Affordable Housing
                </MenuItem>
                <MenuItem value={"Health Care"}>Health Care</MenuItem>
                <MenuItem value={"Digital Superhighway and Creative Economy"}>
                  Digital Superhighway and Creative Economy
                </MenuItem>
                <MenuItem value={"Infrastructure"}>Infrastructure</MenuItem>
                <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
                <MenuItem value={"Service Economy"}>Service Economy</MenuItem>
                <MenuItem value={"Education"}>Education</MenuItem>
                <MenuItem value={"Environment and Climate Change"}>
                  Environment and Climate Change
                </MenuItem>
                <MenuItem value={"Women"}>Women</MenuItem>
                <MenuItem value={"Social Protection"}>
                  Social Protection
                </MenuItem>
                <MenuItem value={"Governance"}>Governance</MenuItem>
                <MenuItem value={"Devolution"}>Devolution</MenuItem>
                <MenuItem value={"Security Services"}>
                  Security Services
                </MenuItem>
                <MenuItem value={"Diaspora"}>Diaspora</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Directive Category</InputLabel>
              <Select
                sx={{ my: 1 }}
                labelId="directive_category"
                id="directive_category"
                name="directive_category"
                label="Directive Category"
                value={directiveCategory}
                onChange={(e) => {
                  setDirectiveCategory(e.target.value);
                }}
              >
                <MenuItem value={"Main Directive"}>Main Directive</MenuItem>
                <MenuItem value={"Sub Directive"}>Sub Directive</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box display="flex" flexDirection="row" gap={2} mt={2}>
            <FormControl fullWidth>
              <InputLabel>Implementation Status</InputLabel>
              <Select
                labelId="implementation_status"
                id="implementation_status"
                name="implementation_status"
                label="Implementation Status"
                value={implementationStatus}
                onChange={(e) => {
                  setImplementationStatus(e.target.value);
                }}
              >
                <MenuItem value={"Not_Started"}>Not Started</MenuItem>

                <MenuItem value={"In_Progress"}>In Progress</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
                <MenuItem value={"Over_Due"}>Over Due</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              {min_state_inst && (
                <Box mb={2}>
                  <Autocomplete
                    fullWidth
                    id="ministry"
                    name="ministry"
                    options={Array.from(
                      new Set(min_state_inst.map((item) => item.Ministry))
                    )
                      .map((ministry) => ministry)
                      .sort()}
                    onChange={(e, newValue) => {
                      handleMinistryChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ministry"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              )}
            </FormControl>
            <FormControl fullWidth>
              {/* {selectedMinistry && ( */}
              <Box mb={2}>
                <Autocomplete
                  fullWidth
                  id="State_departments"
                  name="State_departments"
                  options={department.sort()}
                  onChange={(e, newValue) => {
                    handleStateChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State Departments"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
              {/* )} */}
            </FormControl>
          </Box>

          <Box display="flex" flexDirection="row" gap={2} mt={2}>
            {/* <FormControl fullWidth>
              <InputLabel>County</InputLabel>
              <Select
                labelId="counuty"
                id="counuty"
                name="counuty"
                label="County"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              >
                <MenuItem value={"Not_Started"}>Not Started</MenuItem>
                <MenuItem value={"On_Hold"}>On Hold</MenuItem>

                <MenuItem value={"In_Progress"}>In Progress</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
            </FormControl> */}

            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  fullWidth
                  id="date_issue"
                  name="date_issue"
                  label="Date of Issue"
                  value={dateIssue}
                  onChange={(newValue) =>
                    setDateIssue(
                      newValue ? dayjs(newValue).format("YYYY-MM-DD") : null
                    )
                  }
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  fullWidth
                  id="target_date"
                  name="target_date"
                  label="Target Date"
                  value={targetDate}
                  onChange={(newValue) =>
                    setTargetDate(
                      newValue ? dayjs(newValue).format("YYYY-MM-DD") : null
                    )
                  }
                />
              </LocalizationProvider>
            </FormControl>
          </Box>

          <Grid container spacing={1} my={4}>
            <Grid item xs={9}>
              <Button
                color="error"
                variant="contained"
                onClick={() => {
                  clearFilters();
                  // setOpen(false);
                }}
              >
                Clear filters
              </Button>
            </Grid>
            <Grid item xs={3} className="flex justify-end">
              <Button
                variant="contained"
                color="secondary"
                // onClick={() => setOpen(false)}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </Div>
      </Box>
    </Div>
  );
};

export default DirectivesFilter;
