import React from "react";
import ProjectItem from "./ProjectItem";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { backend_url } from "app/services/constants";
import { useState } from "react";
import DirectivesFilter from "./directivesFilter";
import Div from "@jumbo/shared/Div";
import { ThePlan } from "./ThePlan";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Pagination from "./Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom/dist";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProjectsList = ({ token, onSearch }) => {
  const [weights, setWeights] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [manifestoTrackingCategory, setManifestoTrackingCategory] =
    useState("");

  const theme = useTheme();
  let query = useQuery();
  const county = query.get("county");
  const implementation_status = query.get("implementation_status");
  const cost = query.get("cost");
  const [costMin, setCostMin] = useState(0);
  const [costMax, setCostMax] = useState(cost !== null ? cost : 900000000);

  const [costRange, setCostRange] = useState([
    0,
    cost !== null ? cost : 900000000,
  ]);
  // const [minCost, setMinCost] =useState(cost[0])
  // const [maxCost, setMaxCost] =useState(cost !== null ? cost : 900000000)
  // const [costRange, setCostRange] = useState([
  //   0,
  //   cost !== null ? cost : 900000000,
  // ]);

  const plan = query.get("plan");
  const ministry = query.get("ministry");
  const lead_agency = query.get("lead_agency");

  // const url = `${backend_url}/ppi/directive/?${filter}`;

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 30;
  const totalItems = weights.count;
  // const links = weights.links;
  const totalPages = Math.ceil(totalItems / limit);

  const handlePageChange = (offset) => {
    setCurrentPage(Math.ceil(offset / limit) + 1);
  };

  const url = `${backend_url}/ppi/weights/`;
  console.log("weights", weights);

  useEffect(() => {
    async function fetchData() {
      const offset = (currentPage - 1) * limit;
      const params = new URLSearchParams({ limit: limit, offset: offset });

      const addParam = (key, value) => {
        if (value) params.append(key, value);
      };

      addParam("summary_name__icontains", searchQuery);
      addParam("county__icontains", county);
      addParam("implementation_status__icontains", implementation_status);
      // addParam("budget_lt", costRange[1]);
      // addParam("budget_gt", costRange[0]);
      addParam("manifesto_tracking_category", manifestoTrackingCategory);
      addParam("manifesto_tracking_category__icontains", plan);
      addParam("ministry__icontains", ministry);
      addParam("lead_agency__icontains", lead_agency);

      fetch(`${url}?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setWeights(data));
    }

    fetchData();
  }, [
    token,
    setWeights,
    currentPage,
    url,
    searchQuery,
    cost,
    county,
    manifestoTrackingCategory,
    implementation_status,
    plan,
    costRange,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  return (
    <Div>
      {open !== true && (
        <JumboCardQuick
          title={
            <Div>
              <Typography
                variant={"h2"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                The Plan
              </Typography>

              <Stack direction={"row"} spacing={1}>
                {weights.results !== undefined && (
                  <ThePlan
                    manifestoTrackingCategory={manifestoTrackingCategory}
                    setManifestoTrackingCategory={setManifestoTrackingCategory}
                    token={token}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </Stack>
            </Div>
          }
          wrapperSx={{
            "&:last-child": {
              pb: 0,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        />
      )}
      <Div sx={{ my: 1 }} />

      <JumboCardQuick
        title={
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            sx={{ mr: 2 }}
            fullWidth
          />
        }
        action={
          <IconButton color="primary" onClick={handleSearch} sx={{ mr: 1 }}>
            <SearchIcon />
          </IconButton>
        }
        headerSx={{
          "& .MuiCardHeader-action": {
            my: -0.75,
          },
        }}
        wrapperSx={{
          p: 0,
          "&:last-child": {
            pb: 0,
          },
          "& .MuiCollapse-entered:last-child": {
            "& .MuiListItemButton-root": {
              borderBottom: 0,
              borderBottomColor: "transparent",
            },
          },
        }}
      />

      <Div sx={{ my: 1 }} />

      <JumboCardQuick
        title={
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexWrap: "wrap",
              },
            }}
          >
            <Typography
              variant={"h3"}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  marginBottom: 2,
                },
              }}
            >
              Directives
              {weights !== null ? (
                <Chip
                  sx={{ mx: 2 }}
                  size={"medium"}
                  label={weights.count}
                  color="primary"
                />
              ) : (
                <Chip
                  sx={{ mx: 2 }}
                  size={"medium"}
                  label="0"
                  color="primary"
                />
              )}
            </Typography>
          </Typography>
        }
        action={
          <Button
            sx={{ m: 1, fontWeight: "medium" }}
            size={"medium"}
            color={open === true ? "primary" : "secondary"}
            variant="contained"
            onClick={() => (open === true ? setOpen(false) : setOpen(true))}
          >
            {open === true ? "Hide Filter Directives" : " Filter Directives"}
          </Button>
        }
        headerSx={{
          "& .MuiCardHeader-action": {
            my: -0.75,
          },
        }}
        wrapperSx={{
          p: 0,
          "&:last-child": {
            pb: 0,
          },
          "& .MuiCollapse-entered:last-child": {
            "& .MuiListItemButton-root": {
              borderBottom: 0,
              borderBottomColor: "transparent",
            },
          },
        }}
      >
        {open === true && weights.results !== undefined && (
          <DirectivesFilter
            costMax={costMax}
            costMin={costMin}
            setCostMax={setCostMax}
            setCostMin={setCostMin}
            costRange={costRange}
            weights={weights}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            open={open}
            setOpen={setOpen}
            token={token}
            setWeights={setWeights}
          />
        )}
      </JumboCardQuick>

      <Div sx={{ my: 1 }} />

      {weights.results !== undefined ? (
        weights.results.features === undefined &&
        weights.results.map(
          (directive) =>
            directive !== undefined && (
              <div key={directive.main_directive_id}>
                <ProjectItem
                  key={directive.main_directive_id}
                  dir_id={directive.main_directive_id}
                  percentage={directive.main_directive_percentage}
                  dir={directive}
                  sub={false}
                  token={token}
                />
              </div>
            )
        )
      ) : (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}

      {weights.results !== undefined && (
        <div className="my-16 mx-8">
          <Pagination
            totalItems={totalItems}
            limit={limit}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setCurrentPage={setCurrentPage}
            // previousPage={links.previous}
            // nextPage={links.next}
          />
        </div>
      )}
    </Div>
  );
};

export default ProjectsList;
