import React, { Suspense } from "react";
import { IconButton, ListItem, Typography } from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { backend_url } from "app/services/constants";

const Sidebar = () => {
  const [user, setUser] = React.useState();
  const storedToken = localStorage.getItem("access_token");

  React.useEffect(() => {
    fetch(`${backend_url}/auth/user/me/`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data.user);
        // localStorage.getItem("access_token");
        // localStorage.setItem("designation", data.user.designation);
        data.code === "token_not_valid" && localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [storedToken]);

  return (
    <React.Fragment>
      <SidebarHeader />
      {user !== undefined && (
        <div
          style={{
            paddingTop: "24px",
            paddingLeft: "18px",
            display: "flex",
            flexDirection: "column", // Align items vertically
            justifyContent: "center", // Center vertically
            alignItems: "center", // Center horizontally
            // height: "100vh", // Adjust this as needed to fill the available space
          }}
        >
          <ListItem>
            <Typography sx={{ fontSize: 16 }}>
              {user.designation !== "HE" &&
              (user.designation === "PPI_1" ||
                user.designation === "PPI_2" ||
                user.designation === "PPI_3")
                ? "PPI Unit"
                : user.designation}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography sx={{ fontSize: 16 }}>
              {(user.designation !== "HE" ||
                user.designation !== "PPI_1" ||
                user.designation !== "PPI_2" ||
                user.designation !== "PPI_3") &&
                user.ministry !== null &&
                user.ministry
                  .toLowerCase()
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography sx={{ fontSize: 16 }}>
              {(user.designation !== "HE" ||
                user.designation !== "PPI_1" ||
                user.designation !== "PPI_2" ||
                user.designation !== "PPI_3" ||
                user.designation !== "CS") &&
                user.State_departments !== null &&
                user.State_departments.toLowerCase().replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                )}
            </Typography>
          </ListItem>
        </div>
      )}
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menus} />
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
