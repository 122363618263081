import React, { useState, useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import CommentsList from "./CommentsList";
import { Chip, Typography } from "@mui/material";
import NewComment from "./NewComment";
import { backend_url } from "app/services/constants";
import Div from "@jumbo/shared/Div";

const Comments = ({ scrollHeight, token, directive_no, listView }) => {
  const [commentList, setCommentList] = useState([]);
  const [updated, setUpdated] = useState();

  useEffect(() => {
    async function fetchData() {
      fetch(`${backend_url}/ppi/comments/?directive_no=${directive_no}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setCommentList(data));
    }

    fetchData();
  }, [token, directive_no, updated]);

  return listView !== true ? (
    <div>
      {commentList && (
        <JumboCardQuick
          title={
            <Typography variant={"h2"} mt={2}>
              Comments
            </Typography>
          }
          action={
            <Chip
              label={`${commentList.length} Comments`}
              color={"primary"}
              size={"small"}
            />
          }
          headerSx={{ borderBottom: 1, borderBottomColor: "divider" }}
          wrapperSx={{ py: 0 }}
        >
          <JumboScrollbar
            autoHeight
            autoHeightMin={scrollHeight ? scrollHeight : 1080}
            autoHide
            autoHideDuration={200}
            autoHideTimeout={500}
          >
            {commentList.length <= 0 && (
              <Div sx={{ mt: 2, mx: 2, mb: 8 }}>
                <Typography variant={"p"}>
                  There are no comments currently. Check out later or add a
                  comment.
                </Typography>
              </Div>
            )}

            <NewComment
              token={token}
              directive_no={directive_no}
              setUpdated={setUpdated}
            />
            <CommentsList
              commentList={commentList}
              token={token}
              directive_no={directive_no}
            />
          </JumboScrollbar>
        </JumboCardQuick>
      )}
    </div>
  ) : commentList.length > 0 ? (
    <JumboCardQuick
      title={
        <Typography variant={"h2"} mt={2}>
          Comments
        </Typography>
      }
      action={
        <Chip
          label={`${commentList.length} Comments`}
          color={"primary"}
          size={"small"}
        />
      }
      headerSx={{ borderBottom: 1, borderBottomColor: "divider" }}
      wrapperSx={{ p: 0 }}
    >
      <JumboScrollbar
        autoHeight
        autoHeightMin={scrollHeight ? scrollHeight : 480}
        autoHide
        autoHideDuration={200}
        autoHideTimeout={500}
      >
        <CommentsList
          commentList={commentList}
          token={token}
          directive_no={directive_no}
        />
      </JumboScrollbar>
    </JumboCardQuick>
  ) : (
    <Typography variant={"h6"} mt={2}>
      No comments. Click the button to read more on the directive.
    </Typography>
  );
};
/* Todo scrollHeight prop define */
export default Comments;
