import React, { useState } from "react";
import { useFormik } from "formik";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import _ from "@lodash";
import { useNavigate } from "react-router-dom";
import { min_state_inst } from "app/shared/Components/institutions";
import axios from "axios";
import { backend_url } from "app/services/constants";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import InputAdornment from "@mui/material/InputAdornment";
// import kenya_flag from "../assets/kenya_flag.png";
import kenya_flag from "images/kenya_flag.png";

const SignupForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isCS, setIsCS] = useState(false);
  const [isPS, setIsPS] = useState(false);
  const [designation1, setDesignation1] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [filteredMinistry, setFilteredMinistry] = useState([]);
  const [filteredState, setFilteredState] = useState([]);

  const Swal = useSwalWrapper();

  const handleMinistryChange = (newValue) => {
    const selectedMinistry = newValue;

    const filteredMinistries = min_state_inst.filter(
      (item) => item.Ministry === selectedMinistry
    );

    setFilteredMinistry(filteredMinistries);
    setSelectedMinistry(selectedMinistry);
  };

  const handleStateChange = (newValue) => {
    const selectedState = newValue;

    // Filter the data based on the selected Ministry
    const filteredStates =
      selectedState === "None"
        ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
        : min_state_inst.filter(
            (item) => item.State_Departments === selectedState
          );

    setFilteredState(filteredStates);
    setSelectedState(selectedState);
  };

  const ministries = _.uniqWith(
    min_state_inst.map((min) => min.Ministry),
    _.isEqual
  );

  const department =
    filteredMinistry &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const institution =
    filteredState &&
    _.uniqWith(
      filteredState.map((institution) => institution.Institutions),
      _.isEqual
    );

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Your email should end with .go.ke")
      .required("You must enter a valid email"),
    first_name: yup.string().required("Please enter your first name."),
    last_name: yup.string().required("Please enter your last name."),
    id_number: yup
      .number()
      .required("You must enter your ID number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    telephone_no: yup
      .string()
      .required("You must enter your personal number")
      .min(3, "Number is too short - should be 7 chars minimum")
      .nullable(true),
    personal_no: yup
      .string()
      .required("You must enter your personal number")
      .min(3, "Number is too short - should be 7 chars minimum")
      .nullable(true),
    gender: yup
      .string()
      .required()
      .oneOf(["F", "M", "B"], "Please select the gender"),
    designation: yup
      .string()
      .required()
      .oneOf(
        ["CS", "PS", "PPPI", "COSHOPS", "HE"],
        "Please select the designation"
      ),
    ministry: yup
      .string()
      .required("You must enter your ministry")
      .oneOf(
        ministries !== false ? ministries : [],
        "Please select the ministry"
      ),
    State_departments:
      isCS === true
        ? yup.string().notRequired()
        : yup
            .string()
            .required("You must enter your state department")
            .oneOf(
              department !== false ? department : [],
              "Please select the state department"
            ),
    acceptTermsConditions: yup
      .boolean()
      .oneOf([true], "The terms and conditions must be accepted."),
  });

  const defaultValues = {
    email: "",
    first_name: "",
    last_name: "",
    id_number: "",
    telephone_no: "",
    personal_no: "",
    gender: "",
    designation: "",
    ministry: [],
    State_departments: "None",
    acceptTermsConditions: false,
  };

  function submit({
    first_name,
    last_name,
    email,
    gender,
    id_number,
    telephone_no,
    personal_no,
    designation,
    ministry,
    State_departments,
  }) {
    const user = {
      first_name,
      last_name,
      email,
      gender,
      id_number,
      telephone_no,
      personal_no,
      designation,
      State_departments:
        State_departments === "None" || State_departments === undefined
          ? null
          : State_departments,
      ministry: ministry === "None" ? null : ministry,
    };
    axios
      .post(
        `${backend_url}/auth/user/register/`,
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Signed up successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
        localStorage.clear();
        navigate("/confirmation");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: { defaultValues },
    validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);

    // Log the fields with errors
    // const fieldsWithErrors = Object.keys(formik.errors);
    // console.log("Fields with errors:", fieldsWithErrors);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <Form noValidate autoComplete="off"> */}
      <Div sx={{ mb: 3, }}>
        <TextField
          fullWidth
          id="first_name"
          name="first_name"
          label="First Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.first_name}
          error={
            (showErrors || formik.touched.first_name) &&
            Boolean(formik.errors.first_name)
          }
          helperText={
            (showErrors || formik.touched.first_name) &&
            formik.errors.first_name
          }
        />
      </Div>
      <Div sx={{ mb: 3, mt: 1 }}>
        <TextField
          fullWidth
          id="last_name"
          name="last_name"
          label="Last Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.last_name}
          error={
            (showErrors || formik.touched.last_name) &&
            Boolean(formik.errors.last_name)
          }
          helperText={
            (showErrors || formik.touched.last_name) && formik.errors.last_name
          }
        />
      </Div>
      <Div sx={{ mb: 3, mt: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="designation">Designation</InputLabel>
          <Select
            labelId="designation"
            id="designation"
            name="designation"
            label="Designation"
            value={formik.values.designation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (showErrors || formik.touched.designation) &&
              Boolean(formik.errors.designation)
            }
          >
            <MenuItem
              value={"PS"}
              onClick={() => {
                setIsPS(true);
                setIsCS(false);
                setDesignation1(true);
              }}
            >
              PS
            </MenuItem>
            <MenuItem
              value={"CS"}
              onClick={() => {
                setIsPS(false);
                setIsCS(true);
                setDesignation1(true);
              }}
            >
              CS
            </MenuItem>
          </Select>
          {(showErrors || formik.touched.designation) &&
            formik.errors.designation && (
              <FormHelperText>{formik.errors.designation}</FormHelperText>
            )}
        </FormControl>
      </Div>

      {designation1 === true && min_state_inst && (
        <Div sx={{ mb: 3, mt: 1 }}>
          <Autocomplete
            fullWidth
            id="ministry"
            name="ministry"
            options={Array.from(
              new Set(min_state_inst.map((item) => item.Ministry))
            )
              .map((ministry) => ministry)
              .sort()}
            value={formik.values.ministry}
            onChange={(e, newValue) => {
              formik.setFieldValue("ministry", newValue);
              handleMinistryChange(newValue);
            }}
            onBlur={formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ministry"
                error={
                  (showErrors || formik.touched.ministry) &&
                  Boolean(formik.errors.ministry)
                }
                helperText={
                  (showErrors || formik.touched.ministry) &&
                  formik.errors.ministry
                }
              />
            )}
          />
        </Div>
      )}
      {isCS === false && selectedMinistry && (
        <Div sx={{ mb: 3, mt: 1 }}>
          <Autocomplete
            fullWidth
            id="State_departments"
            name="State_departments"
            options={department.sort()}
            value={formik.values.State_departments}
            onChange={(e, newValue) => {
              formik.setFieldValue("State_departments", newValue);
              handleStateChange(newValue);
            }}
            onBlur={formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State_departments"
                error={
                  (showErrors || formik.touched.State_departments) &&
                  Boolean(formik.errors.State_departments)
                }
                helperText={
                  (showErrors || formik.touched.State_departments) &&
                  formik.errors.State_departments
                }
              />
            )}
          />
        </Div>
      )}

      {isCS === false && isPS === false && selectedState && (
        <Div sx={{ mb: 3, mt: 1 }}>
          <Autocomplete
            fullWidth
            id="institutions"
            name="institutions"
            options={institution.sort()}
            value={formik.values.institutions}
            onChange={(e, newValue) =>
              formik.setFieldValue("institutions", newValue)
            }
            onBlur={formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Institution"
                error={
                  (showErrors || formik.touched.institutions) &&
                  Boolean(formik.errors.institutions)
                }
                helperText={
                  (showErrors || formik.touched.institutions) &&
                  formik.errors.institutions
                }
              />
            )}
          />
        </Div>
      )}

      <Div sx={{ mb: 3, mt: 1 }}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={
            (showErrors || formik.touched.email) && Boolean(formik.errors.email)
          }
          helperText={
            (showErrors || formik.touched.email) && formik.errors.email
          }
        />
      </Div>
      <Div sx={{ mb: 3, mt: 1 }}>
        <TextField
          fullWidth
          id="id_number"
          name="id_number"
          label="ID Number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.id_number}
          error={
            (showErrors || formik.touched.id_number) &&
            Boolean(formik.errors.id_number)
          }
          helperText={
            (showErrors || formik.touched.id_number) && formik.errors.id_number
          }
        />
      </Div>
      <Div sx={{ mb: 3, mt: 1 }}>
        <TextField
          fullWidth
          id="telephone_no"
          name="telephone_no"
          label="Telephone Number"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.telephone_no}
          error={
            (showErrors || formik.touched.telephone_no) &&
            Boolean(formik.errors.telephone_no)
          }
          helperText={
            (showErrors || formik.touched.telephone_no) &&
            formik.errors.telephone_no
          }
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component="span"
                  sx={{ "& > img": { mr: 0, flexShrink: 0 } }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={kenya_flag}
                    alt="kenya flag"
                  />
                  <span className="ml-4 font-medium">+254</span>
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </Div>

      <Div sx={{ mb: 3, mt: 1 }}>
        <TextField
          fullWidth
          id="personal_no"
          name="personal_no"
          label="Personal/Employment Number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.personal_no}
          error={
            (showErrors || formik.touched.personal_no) &&
            Boolean(formik.errors.personal_no)
          }
          helperText={
            (showErrors || formik.touched.personal_no) &&
            formik.errors.personal_no
          }
        />
      </Div>

      <Div sx={{ mb: 3, mt: 1 }}>
        <FormControl sx={{ mb: 2 }} fullWidth>
          <InputLabel id="gender">Gender</InputLabel>
          <Select
            labelId="gender"
            id="gender"
            name="gender"
            label="Gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (showErrors || formik.touched.gender) &&
              Boolean(formik.errors.gender)
            }
          >
            <MenuItem value={"M"}>Male</MenuItem>
            <MenuItem value={"F"}>Female</MenuItem>
            <MenuItem value={"B"}>Preferred not to say</MenuItem>
          </Select>
          {(showErrors || formik.touched.gender) && formik.errors.gender && (
            <FormHelperText>{formik.errors.gender}</FormHelperText>
          )}
        </FormControl>
      </Div>
      <Div sx={{ mb: 2, mt: 1 }}>
        {/* <JumboAppTextField
              fullWidth
              name="password"
              label="Password"
              type="password"
            /> */}
      </Div>

      <Div sx={{ mb: 2, mt: 1 }}>
        <FormControl
          className="items-center"
          error={
            (showErrors || formik.touched.acceptTermsConditions) &&
            Boolean(formik.errors.acceptTermsConditions)
          }
        >
          <FormControlLabel
            label={
              <Link to="/terms-and-conditions" underline="none" target="_blank">
                {" "}
                I agree to the Terms of Service and Privacy Policy
              </Link>
            }
            control={
              <Checkbox
                size="small"
                value={formik.values.acceptTermsConditions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
          />
          {(showErrors || formik.touched.gender) && formik.errors.gender && (
            <FormHelperText>
              {formik.errors.acceptTermsConditions}
            </FormHelperText>
          )}
        </FormControl>
      </Div>
      <Button
        fullWidth
        type="submit"
        color="secondary"
        variant="contained"
        size="large"
        sx={{ mb: 3 }}
        loading={loading}
        onClick={() => {
          // navigate("/");
          handleFieldErrors();
          setLoading(true);
        }}
        // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
      >
        Signup
      </Button>
    </form>
  );
};

export default SignupForm;
