import React from "react";
import Avatar from "@mui/material/Avatar";
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import his_excellency from "../../../../images/his_excellency.jpg";
import { backend_url } from "app/services/constants";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(0, 1),

  "&:hover": {
    backgroundColor: "transparent",
  },

  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

const Item = styled("div")({
  textAlign: "center",
});

const Header = ({ user }) => {
  return (
    <ContentHeader
      avatar={
        <Avatar
          sx={{ width: 72, height: 72 }}
          alt={user.first_name}
          src={
            user.designation === "HE"
              ? his_excellency
              : `${backend_url}${user.profile_photo}`
          }
          // src={getAssetPath(`${ASSET_AVATARS}/his_excellency.jpg`, "72x72")}
        />
      }
      title={`${
        user.designation === "HE"
          ? "His Excellency"
          : (user.first_name, user.last_name)
      } `}
      subheader={
        <Typography fontSize={12} variant={"body1"} color={"inherit"} mt={0.5}>
          {user.designation !== "HE" &&
          (user.designation === "PPI_1" ||
            user.designation === "PPI_2" ||
            user.designation === "PPI_3")
            ? "PPI Unit"
            : user.designation}
        </Typography>
      }
      children={
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            mx: 1,
          }}
        >
          <Item>
            {/* <Typography variant={"h6"} color={"inherit"} mb={0}>
              2
            </Typography> */}
            <Typography variant={"body1"} fontSize={12}>
              PDMIS
            </Typography>
          </Item>
          {/* <Item>
            <Typography variant={"h6"} color={"inherit"} mb={0}>
              6
            </Typography>
            <Typography variant={"body1"} fontSize={12}>
              Ongoing
            </Typography>
          </Item>
          <Item>
            <Typography variant={"h6"} color={"inherit"} mb={0}>
              2
            </Typography>
            <Typography variant={"body1"} fontSize={12}>
              On Hold
            </Typography>
          </Item> */}
        </Stack>
      }
      //   tabs={
      //     <List
      //       disablePadding
      //       sx={{
      //         display: "flex",
      //         minWidth: 0,
      //       }}
      //     >
      //       <StyledMenuItem>Timeline</StyledMenuItem>
      //       <StyledMenuItem>About</StyledMenuItem>
      //       <StyledMenuItem>Photos</StyledMenuItem>
      //       <StyledMenuItem>Friends</StyledMenuItem>
      //       <StyledMenuItem>More</StyledMenuItem>
      //     </List>
      //   }
      //   action={
      //     <Button
      //       disableRipple
      //       variant="text"
      //       startIcon={<SettingsIcon />}
      //       sx={{
      //         color: "inherit",
      //         textTransform: "none",
      //         "&:hover": {
      //           backgroundColor: "transparent",
      //         },
      //       }}
      //     >
      //       Settings
      //     </Button>
      //   }
      sx={{
        position: "relative",
        zIndex: 1,

        "& .MuiCardHeader-action": {
          alignSelf: "center",
        },
      }}
    />
  );
};

export default Header;
