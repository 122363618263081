import Div from "@jumbo/shared/Div";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";

export const FilterReports = (props) => {
  const {
    selectedPlan,
    handlePlanChange,
    weightsResults,
    // uniquePlans,
    selectedCounty,
    handleCountyChange,
    // uniqueCounty,
    selectedMinistry,
    handleMinistryChange,
    // uniqueMinistry,
    selectedDepartment,
    handleDepartmentChange,
    // uniqueLead,
    dateFilter,
    handleDateFilterChange,
    performanceRange,
    handlePerformanceChange,
  } = props;

  const uniquePlans = [...new Set(weightsResults.map((item) => item.plan))];
  const uniqueCounty = [...new Set(weightsResults.map((item) => item.county))];
  const uniqueMinistry = [...new Set(weightsResults.map((item) => item.ministry))];
  const uniqueLead = [...new Set(weightsResults.map((item) => item.state_department))];

  return (
    <Div sx={{ px: 2 }}>
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="plan-label">Select Plan</InputLabel>
            <Select
              labelId="plan-label"
              id="plan"
              value={selectedPlan}
              label="Select Plan"
              onChange={handlePlanChange}
            >
              <MenuItem value="">All</MenuItem>
              {uniquePlans.map((plan) => (
                <MenuItem key={plan} value={plan}>
                  {plan}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="ministry-label">Select Ministry</InputLabel>
            <Select
              labelId="ministry-label"
              id="ministry"
              value={selectedMinistry}
              label="Select Ministry"
              onChange={handleMinistryChange}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueMinistry.map((ministry) => (
                <MenuItem key={ministry} value={ministry}>
                  {ministry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="department-label">
              Select State Department
            </InputLabel>
            <Select
              labelId="department-label"
              id="department"
              value={selectedDepartment}
              label="Select State Department"
              onChange={handleDepartmentChange}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueLead.map((state_department) => (
                <MenuItem key={state_department} value={state_department}>
                  {state_department}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="date-filter-label">Select Date Filter</InputLabel>
            <Select
              labelId="date-filter-label"
              id="date-filter"
              value={dateFilter}
              label="Select Date Filter"
              onChange={handleDateFilterChange}
            >
              <MenuItem value="7days">Last 7 Days</MenuItem>
              <MenuItem value="3months">Last 3 Months</MenuItem>
              <MenuItem value="custom">Custom Date</MenuItem>
              <MenuItem value="all">All Dates</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Div sx={{ py: 2 }}>
        <Grid container spacing={3.75}>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="County-label">Select County</InputLabel>
              <Select
                labelId="County-label"
                id="County"
                value={selectedCounty}
                label="Select County"
                onChange={handleCountyChange}
              >
                <MenuItem value="">All</MenuItem>
                {uniqueCounty.map((county) => (
                  <MenuItem key={county} value={county}>
                    {county}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={6} lg={9}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="performance"
                name="performance"
                value={performanceRange}
                onChange={handlePerformanceChange}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All Performance"
                />
                <FormControlLabel
                  value="completed"
                  control={<Radio />}
                  label="Completed"
                />
                <FormControlLabel
                  value="ongoing"
                  control={<Radio />}
                  label="Ongoing"
                />
                <FormControlLabel
                  value="notStarted"
                  control={<Radio />}
                  label="Not_Started"
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
        </Grid>
      </Div>
    </Div>
  );
};
