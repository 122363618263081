import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Sector } from "recharts";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List, ListItemButton, Typography } from "@mui/material";
import { Link } from "react-router-dom/dist";

const RadialPieChart = ({ data, implStatusCounts }) => {
  const [chartData, setChartData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const COLORS = {
    "Not_Started": "#E73145",
    "In_Progress": "#3E5ED6",
    "Over_Due": "#F39711",
    "Completed": "#00A073"
  };

  useEffect(() => {
    drawChart();
  }, [data]);

  const drawChart = () => {
    const newData = Object.entries(implStatusCounts).map(([status, count]) => ({
      name: status,
      value: count,
    }));
    setChartData(newData);
  };

  function renderActiveShape(props) {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 8}
          outerRadius={outerRadius + 11}
          fill={fill}
        />
      </g>
    );
  }

  function onPieHover(_, index) {
    setActiveIndex(index);
  }

  function onMouseLeave() {
    setActiveIndex(0);
  }

  return (
    <div>
      <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
        <Typography fontWeight="500" variant="h3" sx={{ mb: 0 }}>
          Directives per status implementation
        </Typography>
        <div>
          <PieChart width={300} height={280}>
            <Pie
              activeIndex={activeIndex}
              data={chartData}
              dataKey="value"
              nameKey="name"
              outerRadius={100}
              innerRadius={80}
              activeShape={renderActiveShape}
              onMouseOver={onPieHover}
              onMouseLeave={onMouseLeave}
            >
              {chartData.map((entry, index) => {
                const color = COLORS[entry.name] || COLORS.default;
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={color}
                  />
                );
              })}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
        <List disablePadding>
          {chartData.map((entry, index) => {
            const color = COLORS[entry.name];
            return (
              <ListItemButton
                key={index}
                className={activeIndex === index ? "active" : ""}
                onMouseOver={onPieHover.bind(null, null, index)}
                onMouseLeave={onMouseLeave.bind(null, null, index)}
              >
                {entry.name === "Not_Started" && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: color,
                      fontSize: "16px",
                      textDecoration: "none",
                    }}
                    component={Link}
                    to="/directives?implementation_status=Not_Started&category=main_directive"
                  >
                    Not Started: <span>{entry.value} Directives</span>
                  </Typography>
                )}{" "}
                {entry.name === "In_Progress" && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: color,
                      fontSize: "16px",
                      textDecoration: "none",
                    }}
                    component={Link}
                    to="/directives?implementation_status=In_Progress&category=main_directive"
                  >
                    In Progress: <span>{entry.value} Directives</span>
                  </Typography>
                )}{" "}
                {entry.name === "Over_Due" && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: color,
                      fontSize: "16px",
                      textDecoration: "none",
                    }}
                    component={Link}
                    to="/directives?implementation_status=Over_Due&category=main_directive"
                  >
                    Over Due: <span>{entry.value} Directives</span>
                  </Typography>
                )}{" "}
                {entry.name === "Completed" && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: color,
                      fontSize: "16px",
                      textDecoration: "none",
                    }}
                    component={Link}
                    to="/directives?implementation_status=Completed&category=main_directive"
                  >
                    Completed: <span>{entry.value} Directives</span>
                  </Typography>
                )}
              </ListItemButton>
            );
          })}
        </List>
      </JumboCardQuick>
    </div>
  );
};

export default RadialPieChart;
