import { useEffect, useState } from "react";
import { Button, Paper, Grid, Typography } from "@mui/material";
import CustomBarChart from "./widgets/BarChart";
import RadialPieChart from "./widgets/RadialPieChart";
import Div from "@jumbo/shared/Div";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  ObjectCountOrders,
  ObjectCountQueries,
  ObjectCountRevenue,
  ObjectCountVisits,
  ObjectCountNotStarted,
} from "./widgets/ObjectCountCards";
import * as d3 from "d3";
import PercentageDistributionChart from "./widgets/PercentageDistributionChart ";
import LocationDirectives from "./widgets/LocationDirectives";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { FilterReports } from "./FilterReports";
import { useTheme } from "@emotion/react";
import BudgetsTabs from "./widgets/BudgetsTabs";
import ObjectCounty from "./widgets/ObjectCountCards/ObjectCounty";
import CountiesInfo from "./info/CountiesInfo";
import ObjectCountOverDue from "./widgets/ObjectCountCards/ObjectCountOverDue";

const Reports = ({ resp, weightsResults, counties, setCounties }) => {
  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [performanceRange, setPerformanceRange] = useState("all");
  const [dateFilter, setDateFilter] = useState("");
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  const handleMinistryChange = (event) => {
    setSelectedMinistry(event.target.value);
  };

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleCountyChange = (event) => {
    setSelectedCounty(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handlePerformanceChange = (event) => {
    setPerformanceRange(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const filterData = (data, filters) => {
    return data.filter((item) =>
      Object.entries(filters).every(
        ([key, value]) => !value || item[key] === value
      )
    );
  };

  const calculatePerformance = (percentage) => {
    if (percentage === 100) return "completed";
    if (percentage > 0 && percentage < 100) return "ongoing";
    if (percentage === 0) return "notStarted";
    return "notStarted";
  };

  // const calculateTotalBudget = (data) => {
  //   const flattenDirectives = data.flatMap((item) =>
  //     item.directives.map((directive) => ({
  //       ministry: item.ministry,
  //       state_department: item.state_department,
  //       plan: item.plan,
  //       ...directive,
  //     }))
  //   );
  //   const totalSum = d3.sum(flattenDirectives, (d) => d.budgets || 0);

  //   return totalSum;
  // };

  const calculateFilteredBudget = (data, filters) => {
    const filteredData = filterData(data, filters);
    const filteredSum = d3.sum(filteredData, (item) =>
      d3.sum(item.directives, (directive) => directive.budget || 0)
    );

    return filteredSum;
  };

  // const totalSum = calculateTotalBudget(weights);

  // Apply Date Filter
  const currentDate = new Date();
  const filteredData = filterData(weightsResults, {
    ministry: selectedMinistry,
    plan: selectedPlan,
    county: selectedCounty,
    state_department: selectedDepartment,
  }).filter((item) => {
    if (!dateFilter || dateFilter === "all") return true;

    const itemDate = new Date(item.date_issue);
    switch (dateFilter) {
      case "7days":
        return currentDate - itemDate <= 7 * 24 * 60 * 60 * 1000;
      case "3months":
        return currentDate - itemDate <= 3 * 30 * 24 * 60 * 60 * 1000;
      case "custom":
        // You may need to handle the custom date filter logic here
        return true;
      default:
        return false;
    }
  });

  const filteredSum = calculateFilteredBudget(filteredData, {
    ministry: selectedMinistry,
    plan: selectedPlan,
    county: selectedCounty,
    state_department: selectedDepartment,
  });

  // Performance Calculation
  const performanceData = filteredData.map((item) => {
    const category = calculatePerformance(item.main_directive_percentage * 100);
    // const category1 = calculatePerformance(item.main_directive_percentage * 100);
    return {
      ...item, 
      category,
    };
  });

  // Filtered Performance Data
  const filteredPerformanceData = filterData(performanceData, {
    category: performanceRange === "all" ? "" : performanceRange,
  });

  // Count unique counties
  const uniqueCountiesSet = new Set();
  filteredData.forEach((item) => {
    const { county } = item;
    uniqueCountiesSet.add(county);
  });

  const implStatusCounts = {};
  filteredData.forEach((directive) => {
    const status = directive.directives[0].implementation_status;
    if (implStatusCounts[status]) {
      implStatusCounts[status]++;
    } else {
      implStatusCounts[status] = 1;
    }
  });

  const numberOfUniqueCounties = uniqueCountiesSet.size;

  const calculateStatusCounts = (data) => {
    const statusCounts = {
      notStarted: 0,
      ongoing: 0,
      completed: 0,
    };

    data.forEach((item) => {
      const percentage = item.main_directive_percentage * 100;
      if (percentage === 0) {
        statusCounts.notStarted += 1;
      } else if (percentage > 0 && percentage < 100) {
        statusCounts.ongoing += 1;
      } else if (percentage === 100) {
        statusCounts.completed += 1;
      }
    });

    return statusCounts;
  };

  const statusCounts = calculateStatusCounts(filteredPerformanceData);

  return resp === false ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Div>
      <Div sx={{ mb: 3 }}>
        <JumboCardQuick
          title={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant={"h3"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                Dashboard
              </Typography>
            </Typography>
          }
          action={
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(open === true ? false : true);
              }}
            >
              {open === true ? "Close Filters" : "Filter Reports"}
            </Button>
          }
          // sx={{ bgColor: "inherit" }}
          // bgColor={{}}
          noWrapper={true}
          headerSx={{
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          wrapperSx={{
            p: 0,
            "&:last-child": {
              pb: 0,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          {weightsResults !== undefined && open === true && (
            <FilterReports
              selectedPlan={selectedPlan}
              handlePlanChange={handlePlanChange}
              weightsResults={weightsResults}
              // uniquePlans={uniquePlans}
              selectedCounty={selectedCounty}
              handleCountyChange={handleCountyChange}
              // uniqueCounty={uniqueCounty}
              selectedMinistry={selectedMinistry}
              handleMinistryChange={handleMinistryChange}
              // uniqueMinistry={uniqueMinistry}
              selectedDepartment={selectedDepartment}
              handleDepartmentChange={handleDepartmentChange}
              // uniqueLead={uniqueLead}
              dateFilter={dateFilter}
              handleDateFilterChange={handleDateFilterChange}
              performanceRange={performanceRange}
              handlePerformanceChange={handlePerformanceChange}
            />
          )}
        </JumboCardQuick>
      </Div>

      {counties === true && (
        <CountiesInfo
          counties={counties}
          setCounties={setCounties}
          data={filteredPerformanceData}
        />
      )}

      {filteredPerformanceData && (
        <>
          <Grid container spacing={3.75}>
            {statusCounts !== undefined && (
              <Grid item xs={12} sm={6} lg={4}>
                <RadialPieChart
                  data={statusCounts}
                  implStatusCounts={implStatusCounts}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} lg={8} sx={{ height: 400, marginBottom: '96px' }}>
              <Div sx={{ mb: 3 }}>
                <Grid container spacing={3.75}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ObjectCountOrders
                      totalDirectives={filteredPerformanceData.length}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ObjectCountRevenue totalCost={filteredSum} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ObjectCounty
                      counties={counties}
                      setCounties={setCounties}
                      numberOfUniqueCounties={numberOfUniqueCounties}
                      inProgress={
                        implStatusCounts.In_Progress === undefined
                          ? 0
                          : implStatusCounts.In_Progress
                      }
                      totalDirectives={filteredPerformanceData.length}
                    />
                  </Grid>
                </Grid>
              </Div>
              <Div>
                <Grid container spacing={3.75}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountVisits
                      inProgress={
                        implStatusCounts.In_Progress === undefined
                          ? 0
                          : implStatusCounts.In_Progress
                      }
                      totalDirectives={filteredPerformanceData.length}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountQueries
                      completed={
                        implStatusCounts.Completed === undefined
                          ? 0
                          : implStatusCounts.Completed
                      }
                      totalDirectives={filteredPerformanceData.length}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountOverDue
                      overDue={
                        implStatusCounts.Over_Due === undefined
                          ? 0
                          : implStatusCounts.Over_Due
                      }
                      totalDirectives={filteredPerformanceData.length}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountNotStarted
                      notStarted={
                        implStatusCounts.Not_Started === undefined
                          ? 0
                          : implStatusCounts.Not_Started
                      }
                      totalDirectives={filteredPerformanceData.length}
                    />
                  </Grid>
                </Grid>
              </Div>
            </Grid>


            <Grid item xs={12} sm={6} lg={4}>
              <Paper>
                <BudgetsTabs
                  filteredPerformanceData={filteredPerformanceData}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} lg={8}>
              <CustomBarChart data={filteredPerformanceData} />
            </Grid>

            <Grid item xs={12}>
              <PercentageDistributionChart
                notStarted={
                  implStatusCounts.Not_Started === undefined
                    ? 0
                    : implStatusCounts.Not_Started
                }
                completed={
                  implStatusCounts.Completed === undefined
                    ? 0
                    : implStatusCounts.Completed
                }
                inProgress={
                  implStatusCounts.In_Progress === undefined
                    ? 0
                    : implStatusCounts.In_Progress
                }
                overDue={
                  implStatusCounts.Over_Due === undefined
                    ? 0
                    : implStatusCounts.Over_Due
                }
                data={filteredPerformanceData}
              />
            </Grid>

            <Grid item xs={12}>
              <LocationDirectives data={filteredPerformanceData} />
            </Grid>

            {/* <Grid item xs={12} sm={6} lg={4}>
              <CustomBarChart data={filteredPerformanceData} />
            </Grid> */}
          </Grid>
        </>
      )}
    </Div>
  );
};

export default Reports;
