import React from "react";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
// import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DnsIcon from "@mui/icons-material/Dns";

const designation = localStorage.getItem("designation");

const menus = [
  {
    label: "Dashboard",
    type: "section",
    children: [
      designation !== "PS" && {
        uri: "/summary",
        label: "Summary",
        type: "nav-item",
        icon: <AnalyticsIcon sx={{ fontSize: 20 }} />,
      },
      designation !== "PS" && {
        uri: "/directives",
        label: "Directives",
        type: "nav-item",
        icon: <FolderCopyIcon sx={{ fontSize: 20 }} />,
      },
      designation !== "PS" && {
        uri: "/priority-projects",
        label: "Presidential Priority Projects",
        type: "nav-item",
        icon: <DnsIcon sx={{ fontSize: 20 }} />,
      },
      designation === "PS" && {
        uri: "/directives",
        label: "Directives",
        type: "nav-item",
        icon: <FolderCopyIcon sx={{ fontSize: 20 }} />,
      },
      designation === "CS" && {
        uri: "/collaborating-directives",
        label: "Collaborating Directives",
        type: "nav-item",
        icon: <FolderCopyIcon sx={{ fontSize: 20 }} />,
      },
      designation === "PS" && {
        uri: "/collaborating-directives",
        label: "Collaborating Directives",
        type: "nav-item",
        icon: <FolderCopyIcon sx={{ fontSize: 20 }} />,
      },
      designation === "PS" && {
        uri: "/summary",
        label: "Summary",
        type: "nav-item",
        icon: <AnalyticsIcon sx={{ fontSize: 20 }} />,
      },
      designation === "PPI_1" && {
        uri: "/directive/new",
        label: "New Directive",
        type: "nav-item",
        icon: <EditIcon sx={{ fontSize: 20 }} />,
      },
      designation === "PPI_2" && {
        uri: "/directive/new",
        label: "New Directive",
        type: "nav-item",
        icon: <EditIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },

  {
    label: "Other",
    type: "section",
    children: [
      // {
      //   uri: "/widgets",
      //   label: "Terms and Conditions",
      //   type: "nav-item",
      //   icon: <WidgetsOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/faqs",
        label: "FAQs",
        type: "nav-item",
        icon: <LeaderboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
